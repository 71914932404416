$peach: #FFCA95;
$grape: #7C85AB;
$darkpurple: #475881;
$navy: #20314E;
$pink: #FFA5C0;
$cream: #FBF1D8;
$orange: #FFA852;
$darkorange: #F07E42;


.catty {
  height: 182px;
  width: 200px;
  position: absolute;
  // top: 50%;
  // right: 145px;
  transform: translate(0, -50%);

  .ears1 {
    height: 0;
    width: 0;
    position: relative;
    left: 90px;
    border-bottom: 27px solid $darkpurple;
    border-left: 10px solid transparent;
    border-right: 23px solid transparent;

    &::before {
      display: block;
      content: "";
      height: 0;
      width: 0;
      position: relative;
      left: 24px;
      border-bottom: 27px solid $darkpurple;
      border-left: 10px solid transparent;
      border-right: 23px solid transparent;
    }
  }

  .head1 {
    height: 74px;
    width: 135px;
    position: relative;
    left: 65px;
    z-index: 2;
    box-shadow: -8px 0 0 $darkpurple;
    border-radius: 37px;
    background: $grape;

    .eyes1 {
      height: 12px;
      width: 12px;
      position: relative;
      top: 37px;
      left: 64px;
      border-radius: 100%;
      animation: 9s cattyRead infinite;
      background: black;

      &::before {
        display: block;
        content: "";
        height: 12px;
        width: 12px;
        position: relative;
        left: 18px;
        border-radius: 100%;
        background: black;
      }
    }

    .nose1 {
      height: 22px;
      width: 22px;
      position: relative;
      top: 40px;
      left: 60px;
      border-radius: 20px;
      background: $cream;

      &::before {
        display: block;
        content: "";
        height: 22px;
        width: 22px;
        position: relative;
        left: 22px;
        border-radius: 20px;
        background: $cream;
      }

      &::after {
        display: block;
        content: "";
        height: 0;
        width: 0;
        position: relative;
        top: -22px;
        left: 12px;
        border-radius: 10px;
        border-top: 10px solid $pink;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }

  .body1 {
    height: 110px;
    width: 200px;
    position: relative;
    top: -30px;
    z-index: 1;
    border-radius: 55px;
    background: $grape;

    .left-paw1 {
      height: 25px;
      width: 37px;
      position: relative;
      top: 70px;
      left: 95px;
      border-radius: 12px;
      animation: 9s cattyLeftType infinite;
      background: $cream;
    }

    .right-paw1 {
      height: 25px;
      width: 37px;
      position: relative;
      top: 45px;
      left: 142px;
      border-radius: 12px;
      animation: 9s cattyRightType infinite;
      background: $cream;
    }
  }

  .tail1 {
    height: 24px;
    width: 80px;
    position: relative;
    top: -54px;
    left: -31px;
    z-index: 0;
    border-radius: 17px 0 0 17px;
    background: $darkpurple;
  }

  .PRlaptop {
    position: relative;
    top: -151px;
    left: 170px;
    z-index: 2;

    .PRscreen {
      height: 85px;
      width: 130px;
      border-radius: 8px;
      transform: skew(-18deg);
      background: $navy;

      &::before {
        display: block;
        content: "";
        height: 17px;
        width: 10px;
        position: relative;
        top: 38px;
        left: 56px;
        border-radius: 6px;
        background: $darkpurple;
      }

      &::after {
        display: block;
        content: "";
        height: 17px;
        width: 10px;
        position: relative;
        top: 21px;
        left: 70px;
        border-radius: 6px;
        background: $darkpurple;
      }
    }

    .PRkeyboard {
      height: 12px;
      width: 132px;
      position: relative;
      left: -14px;
      border-radius: 0 6px 6px 0;
      background: $darkpurple;

      &::before {
        display: block;
        content: "";
        height: 12px;
        width: 72px;
        position: relative;
        left: -68px;
        border-radius: 6px;
        background: $navy;
      }
    }
  }
}


.doggy {
  height: 182px;
  // position: absolute;
  // top: 50%;
  // left: 145px;
  transform: translate(0, -50%);
  .ears2 {
    height: 0;
    width: 0;
    position: relative;
    left: 30px;
    border-bottom: 27px solid $darkorange;
    border-left: 23px solid transparent;
    border-right: 10px solid transparent;
    &::before {
      display: block;
      content: "";
      height: 0;
      width: 0;
      position: relative;
      left: 33px;
      border-bottom: 27px solid $darkorange;
      border-left: 10px solid transparent;
      border-right: 23px solid transparent;
    }
  }
  .head2 {
    height: 74px;
    width: 140px;
    position: relative;
    z-index: 2;
    box-shadow: 8px 0 0 $darkorange;
    border-radius: 35px;
    background: $orange;
    .eyes2 {
      height: 12px;
      width: 12px;
      position: relative;
      top: 37px;
      left: 50px;
      border-radius: 100%;
      animation: 9s doggyRead infinite;
      background: black;
      &::before {
        display: block;
        content: "";
        height: 12px;
        width: 12px;
        position: relative;
        left: 18px;
        border-radius: 100%;
        background: black;
      }
    }
    .nose2 {
      height: 30px;
      width: 48px;
      position: relative;
      top: 40px;
      left: 37px;
      border-radius: 20px;
      background: $cream;
      &::before {
        display: block;
        content: "";
        height: 0;
        width: 0;
        position: relative;
        top: 3px;
        left: 9px;
        border-radius: 15px;
        border-top: 12px solid black;
        border-left: 12px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }
  .body2 {
    height: 110px;
    width: 200px;
    position: relative;
    top: -30px;
    z-index: 1;
    border-radius: 55px;
    background: $orange;
    .left-paw2 {
      height: 25px;
      width: 37px;
      position: relative;
      top: 70px;
      left: 15px;
      border-radius: 12px;
      animation: 9s doggyLeftType infinite;
      background: $cream;
    }
    .right-paw2 {
      height: 25px;
      width: 37px;
      position: relative;
      top: 45px;
      left: 60px;
      border-radius: 12px;
      animation: 9s doggyRightType infinite;
      background: $cream;      
    }
  }
  .tail2 {
    height: 34px;
    width: 70px;
    position: relative;
    top: -64px;
    left: 150px;
    z-index: 0;
    border-radius: 0 17px 17px 0;
    background: $darkorange;
  }
  .ORlaptop {
    position: relative;
    top: -161px;
    left: -103px;
    z-index: 2;
    .ORscreen {
      height: 85px;
      width: 130px;
      border-radius: 8px;
      transform: skew(18deg);
      background: $peach;
      &::before{
        display: block;
        content: "";
        height: 17px;
        width: 10px;
        position: relative;
        top: 38px;
        left: 50px;
        border-radius: 6px;
        background: $darkorange;
      }
      &::after{
        display: block;
        content: "";
        height: 17px;
        width: 10px;
        position: relative;
        top: 21px;
        left: 64px;
        border-radius: 6px;
        background: $darkorange;
      }
    }
    .ORkeyboard {
      height: 12px;
      width: 132px;
      position: relative;
      left: 14px;
      border-radius: 6px 0 0 6px;
      background: $darkorange;
      &::before {
        display: block;
        content: "";
        height: 12px;
        width: 72px;
        position: relative;
        left: 128px;
        border-radius: 6px;
        background: $peach;
      }
    }
  }
}

@keyframes cattyRead {
  55% {transform: none}
  62% {transform: translateX(-2px)}
  70% {
    transition-timing-function: ease-out;
    transform: translateX(3px)}
  82% {transform: translateX(-2px)}
  90% {
    transition-timing-function: ease-out;
    transform: translateX(3px)}
  100%{transform: none}   
}

@keyframes cattyLeftType {
  2%  {transform: translateY(-8px)}
  6%  {transform: none}
  8%  {transform: translateY(-8px)}
  10% {transform: none}
  14% {transform: translateY(-8px)}
  16% {transform: none}
  18% {transform: translateY(-8px)}
  20% {transform: none}
  22% {transform: translateY(-8px)}
  26% {transform: none}  
}

@keyframes cattyRightType {
  6%  {transform: translateY(-8px)}
  8%  {transform: none}
  10% {transform: translateY(-8px)}
  12% {transform: none}
  16% {transform: translateY(-8px)}
  18% {transform: none}
  20% {transform: translateY(-8px)}
  22% {transform: none}
  24% {transform: translateY(-8px)}
  28% {transform: none}    
}

@keyframes doggyLeftType {
  50% {transform: none}
  52% {transform: translateY(-8px)}
  56% {transform: none}
  58% {transform: translateY(-8px)}
  60% {transform: none}
  64% {transform: translateY(-8px)}
  66% {transform: none}
  68% {transform: translateY(-8px)}
  70% {transform: none}
  72% {transform: translateY(-8px)}
  76% {transform: none}   
}

@keyframes doggyRightType {
  54% {transform: none}
  56% {transform: translateY(-8px)}
  58% {transform: none}
  60% {transform: translateY(-8px)}
  62% {transform: none}
  66% {transform: translateY(-8px)}
  68% {transform: none}
  70% {transform: translateY(-8px)}
  72% {transform: none}
  74% {transform: translateY(-8px)}
  78% {transform: none} 
}

@keyframes doggyRead {
  5%  {transform: none}
  17% {
    transition-timing-function: ease-out;
    transform: translateX(-5px)}
  25% {transform: none}
  37% {
    transition-timing-function: ease-out;
    transform: translateX(-5px)}
  45% {transform: none}
}