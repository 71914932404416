
html, body{
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  margin: 0;
  padding: 0;
  scroll-behavior: auto !important;
}
p{
  padding: 0;
  margin: 0;
}

button{
  background: #002366;
  color: white;
  border-radius: 7px;
  margin: 0;
  font-size: 14px;
  padding: 10px;
  border: none;
}

button:hover{
  /* color: black;   */
  transition: all 0.5s;
  border: none;
}

::-webkit-scrollbar {
  display: none;
}

/* TEXT HIGHLIGHT  */
::selection {
  background: #82adff;
  color: #000;
}

/* FONT  */
@font-face{
  font-family: myFont;
  src:url(Garet-Book.otf);
}
*{
  font-family: myFont;
}

@font-face{
  font-family: myFontBold;
  src:url(GothamOffice-Bold.otf);
}
h1, .fontSpecial{
  font-family: myFontBold;
  font-weight: 300;
}   

/* HERO MAIN DIV  */
.HeroMainDiv{
  height: 85vh;
  width: 100vw;
  background-color: #FFE699;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroMainDivImage, .HeroMainDivImage2{
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100%; 
  width: 50%;
}

.HeroMainDivText{
  display: flex;
  flex-direction: column;
  width: 70%;
}

.HeroMainDivText2{
  display: flex;
  flex-direction: column;
  width: 70%;
  background: rgba(255, 255, 255, 0.7); 
}

.HeroMainDivText_Pets{
  display: flex; justify-content: center;
  align-items: center; height: 100%; width: 50%; padding: 0; margin: 0;
  padding-top: 20px;
}

.RegistrationWhiteBox{
  width: 60vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px; 
  flex-direction: column;
}

.content{
  min-width: 300px;
  height: 170px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #edeef0;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid rgb(185, 184, 184);
  border-radius: 15px;
  transition: all .3s ease;
  cursor: pointer;
}

.content:hover{
  border-color: #fff;
  background: #FFE699;
}
 

.newcontent{
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #edeef0;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid rgb(185, 184, 184);
  border-radius: 15px;
  transition: all .3s ease;
  cursor: pointer;
}

.newcontent:hover{
  border-color: #fff;
  background: #FFE699;
}
 


.optionBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 20px 20px;
  flex-direction: row;
}
.optionBoxxy{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 30px;
  flex-direction: row;
  gap: 5px;
}
.welcome-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 30px 0px 30px;
  flex-direction: column;
}


.Petty{
  display: flex;
}

.RegistrationFormColumn{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column 
}
.RegistrationFormColumnPage2{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column
}

.ButtonofWhiteBox{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  margin-bottom: 20px;
}

.postnominal{
  font-weight: 900;
  font-size: 13px;
}
.custom-file-upload {
  background: rgb(231, 231, 231);
  color: black;
  font-weight: 900;
  border-radius: 5px;
  margin: 0;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px; 
  padding-bottom: 5px;
  font-size: 13px;
  border: 1px solid grey;
  cursor: pointer;
}

.custom-file-upload:hover{
  background: #dbdbdb;
  transition: all 0.5s;
}
.OnlyColumnPage2{
  width: 100%;
  /* padding-left: 50px;
  padding-right: 50px; */
  padding: 0px 30px 0px 30px;
}

.LogRegButton{
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 0;
  margin: 0;  
  justify-content: flex-start;
  align-items: center;
}

.VetPhoto{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../Homepage/VetPhoto.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40vw;
  height: 40vw;
  transition: background-image 0.7s ease-in-out;  
}

.VetPhoto:hover{
  background-image: url('../Homepage/VetPhotoHover.png');
}



.HealthIssues {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #cbdbfa;
  width: 25%;
  color: black;
  border-radius: 5px;
  transition: background-position 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #cbdbfa;
  transition: height 3s;
  transition-delay: 1s;
  padding: 5px;
}

.HealthIssues:hover {
  border: 2px solid #8db1f8;
}

.HealthIssues2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #faecc3;
  width: 400px;
  color: black;
  border-radius: 5px;
  transition: background-position 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #faecc3;
  transition: height 3s;
  transition-delay: 1s;
  padding: 5px;
}

.HealthIssues2:hover {
  border: 2px solid #FFE699;
}

.dashboardmain{
  min-height: 100vh; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #F5F5F5;
  flex-direction: column;
  width: calc(100vw - 250px);
  padding-bottom: 50px;
}


.divierwelcome{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 15px;
  color: #222222;
}

.divierwelcometext{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  padding: 15px;
  height: 100%;
  flex-direction: column;
}

.divierwelcomeimage{ 
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  padding: 15px;
  height: 100%;
}

.PatientDiv{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100px;
  border-top: 1px solid #c7c3c3;
}
.PatientDiv:hover{
  background: #E5EDFF;
}

.SubPatientDiv{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.AppointmentCodeMain{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* background-color: red; */
  width: 90%;
  /* height: 1000px; */
  margin-top: 20px;
  gap: 20px;
}

.AppointmentCodeLeft{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: blue; */ 
  width: 25%;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
  border: solid 1px rgb(231, 231, 231);
  flex-direction: column;
}

.AppointmentCodeRight{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  width: 75%;
  border: solid 1px rgb(231, 231, 231);
  /* min-height: 1000px; */
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
}

.hidernav{
  text-decoration: none;
  height: 40px;
  font-size: 14px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  text-align: center;
  margin: 20px 0px 0px 0px;
}

.YourPetsDiv{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;  
  height: 100%;
  padding: 20px;
  color: rgb(4, 4, 4);
}

.PetDetailDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; 
  background: #ffffff;
  width: 300px;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
}
.PetDetailDiv:hover{
  transform: scale(1.01);
}

.ConcernDiv{
  display: flex; 
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  background: #f7f7f7;
  /* height: 100%; */
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}



.ConcernDiv:hover{
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  /* background-color: #efefef; */
}

.PetRegDiv{
  display:flex;
  justify-content:flex-start;
  align-items: center;
  background:white;
  width:40%;
  overflow: auto;
  flex-direction:column;
  border-radius: 10px;
}

.DocProfDivLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding: 20px;
  border-radius: 10px;
}

.DocProfDivLeft h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.DocProfilePicture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #0849c1;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
}

.DocInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.DocInfo p {
  margin: 5px 0;
  font-size: 1.1rem;
  color: #555;
}


.DocProfDivRight{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  background: white;
  flex-direction: column
}
 
.DocProfDivMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.DocProfDivMainNew {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 400px;
  gap: 10px;
}

.MainDivDoctorProfile {
  overflow: scroll;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  margin: 40px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.cloud {
  animation: floatUpDown 6s ease-in-out infinite; /* Adjust the duration (4s) as needed */
}

.HoverImage{
  width: 40vw;
  border-radius: 500px;
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.HoverImage:hover{
  /* transition: 1s; */
  /* transform: scale(1.01); */
  filter: grayscale(0%);  
}

.MeetTheTeam{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 7px;
  gap: 20px;
}

.meettheTeamDivier{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  width: 50%;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.TiffDiv{
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: left
}

.JohnDiv{
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: right
}

.PackageHolder{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  gap: 15px;
}

.PackageHolderText{
  width: 50%;
  display: flex;
  padding: 20px;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DogCatImgHolder{
  width: 57%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 20px
}

.DogCatTextHolder{
  width: 43%;
  display: flex;
  padding: 20px;
  text-align: left;
  flex-direction: column;
}

.DogCatImgTextDiv{
  width:100vw;
  background-color: #001337;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.fontsizee{
  width: 80%;
}

.dashboardvideocall{
  height: 100%; 
  min-height: 100vh;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFE699;
  flex-direction: column;
  width: 100vw;
  background-image: url('../Homepage/PawTexture.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.PackageType {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
  background: white;
  width: 80%;
  border-radius: 10px;
  flex-direction: column;
}

.custom-table {
  border-collapse: collapse;
}

.custom-table td {
  padding: 5px;
  color: white;
}

@media screen and (max-width: 1500px) { 
  .meettheTeamDivier{
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .MeetTheTeam{
    flex-direction: column;
  }
}

/* BLOGS SECTION */
.blogcard {
  max-width: 400px;
  margin: 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  background-color: #001844;
  padding: 20px 10px;
  border-radius: 10px;
  transition: transform 0.5s ease; /* Smooth transition for transform */
}

.blogcard:hover {
  transform: translateY(-5px); /* Lift the card upwards on hover */
}

.blogcard-img-top {
  width: 95%;
  border-radius: 10px;
}

.blogcard-title{
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.blogcard-date{
  margin-bottom: 10px;
  font-size: 12px;
  color: #999999;
  font-weight: 900;
}

.blogcard-by{
  font-size: 10px;
  color: #999999;
  font-weight: 900;
  margin-bottom: 10px;
}


.blog-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 50px;
}
 

.hero-sectionw {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  text-align: center;
  position: relative;
}


.hero-titlew {
  position: relative; 
  z-index: 2;
  width: 90vw;
  max-width: 1200px;
  color: black;
  font-size: 2.5rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 1200px;
  text-align: center;
  gap: 20px;
}

.content-section {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f7e9bd;
  /* background-color: #232024; */
  padding: 20px;
  border-radius: 10px;
}

.left .section-image {
  order: 1;
  margin-right: 20px;
}

.right .section-image {
  order: 2;
  margin-left: 20px;
}

.full-width .text-container {
  width: 100%;
  text-align: left;
}

.text-left {
  text-align: left;
  order: 2;
}

.text-right {
  text-align: right;
  order: 1;
}

.text-full-width {
  text-align: left;
}

.text-container {
  width: 100%;
}

.section-image {
  width: 40%;
  height: auto;
}

.section-image2 {
  width: 250px;
  height: auto;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000;
}

.section-paragraph {
  font-size: 14px;
  color: #000;
}

.section-title-big{
  text-transform: capitalize;
  color: #000;
  margin-top: 5px;
}


/* SERVICES CSS STARTS HERE  */
.hero-section {
  position: relative;
  width: 100%;
  height: 60vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.hero-title {
  font-size: 48px;
  margin-bottom: 20px;
  animation: float-in 1s ease-out;
}
.hero-subtitle {
  font-size: 24px;
}
@keyframes float-in {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.card-works {
  position: relative;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  margin: 20px;
  overflow: hidden;
  cursor: pointer;
}
.card {
  margin: 20px;
}
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 1s ease-in-out;
}
.card-front {
  background: white;
  z-index: 2;
}
.card-back {
  background: linear-gradient(135deg, #000000, #002366); 
  transform: translateY(100%);
  z-index: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 8px;
}
.card:hover .card-back {
  transform: translateY(0);
  z-index: 2;
}
.card:hover .card-front {
  transform: translateY(-100%);
  z-index: 1;
}
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color: white;
  
}
.card-description {
  font-size: 14px;
  padding: 10px;
  color: aliceblue;
}
.services-page {
  background-size: cover;
  background-attachment: fixed;
  padding: 0px 20px 50px 20px;
}
.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  perspective: 900px;
}

.outlined-text {
  color: transparent; 
  -webkit-text-stroke: 1px #FFE699; 
  text-transform: uppercase; 
  text-align: center;
  font-weight: bold;
}

.allServices {
  background:#000022
}
.buttonsWhat {
  padding: 10px 30px;
  margin-right: 30px;
  background-color: #2b9159;
  color: white;
  cursor: pointer;
  font-size: 18px; 
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.buttonsWhat:hover {
  background-color: #000f1f;
  transform: translateY(-2px);
  font-weight: 900;
  color: white;
}

/* SERVICES CSS ENDS HERE  */ 








.mainDivContact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  background: #002366;
  margin: 20px 5px;
}

.ContactForm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFEF9;
  flex-direction: column;
  padding: 40px 20px;
  width: 95vw;
  height: 100%;
  max-width: 500px;
  gap: 5px;
  border: 3px solid #002366;
}

.HelpForm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFEF9;
  flex-direction: column;
  padding: 40px 20px;
  width: 95vw;
  height: 100%;
  /* max-width: 500px; */
  gap: 10px;
  /* border: 3px solid #002366; */
}

.inputHelp {
  width: 100%;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 13px;
  padding: 12px;
  color: #002366;
}

.inputContact {
  width: 100%;
  background: transparent;
  border: 2px solid #1b53b9;
  font-size: 13px;
  padding: 12px;
  color: #002366;
}

.imagediv{
  width: 60px;
  display: flex;
  align-items: flex-start;
} 
.cta {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #002366; 
  color: #fff;
  transition: width 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.cta:hover {
  width: 170px;
  background-color: rgb(255, 255, 255);
  color: black;
}

.cta .iii {
  opacity: 1;
  transition: opacity 0.5s;
}

.cta:hover .iii {
  opacity: 0;
}

.cta .button-text {
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
  text-align: center;
  position: absolute; /* Position absolute to overlap with the icon */
  color: black;
}

.cta:hover .button-text {
  opacity: 1;
}


.outlined-textContact {
  color: transparent; 
  -webkit-text-stroke: 2px #002366; 
  text-transform: uppercase; 
  text-align: center;
  font-weight: bold;
}


/* TERMS AND CONDITIONS PAGE STYLING  */
.tc-content-container {
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url("../../1_MediaAssets/MainPages/Blogs/BlogsBG.jpg");
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tc-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  text-align: center;
  position: relative;
}

.tc-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.81); 
  z-index: 1;
}

.tc-hero h1 {
  position: relative;
  z-index: 2;
  width: 97vw;
  max-width: 1200px;
  color: white;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.tc-desc-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  max-width: 1200px;
  gap: 10px;
  padding: 20px;
}

.tc-desc-section h3 {
  color: rgb(0, 0, 0);
  font-size: 1.7rem;
  text-align: left;
  width: 100%;
  padding: 5px;
  margin-bottom: -20px;
}

.tc-desc-section p, .tc-desc-section ul {
  padding: 10px;
  font-size: 14px;
  text-align: left;
}

.tc-desc-section p {
  line-height: 1.6;
  margin: 10px 0; 
}

.tc-desc-section ul {
  line-height: 1.6;
}



.RegistrationWhiteBoxCheckout{
  width: 43vw;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px; 
  flex-direction: column;
  padding: 20px 15px;
  gap: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.error-message, .success-message {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.error-message {
  color: #e74c3c;
  background-color: #fce4e4;
}

.success-message {
  color: #2ecc71;
  background-color: #e4fcef;
}
.checkout-form {
  width: 43vw;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkout-form h2 {
  text-align: center;
  color: #333;
}

.card-element-container {
  padding: 10px; 
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.bookdoctorbutton{
  width: 100px;
  border-radius: 5px;
  font-size: 12px;
  color: #002366;
  border: 1px solid #002366;
  font-weight: 900;
  background-color: transparent;
}

.bookdoctorbutton:hover{
  color: white;
  border: 2px solid #002366;
  background-color: #002366;
}


.headerratespage {
  font-size: 13px;
  padding: 10px;
  text-align: center;

}


.showerNav{
  display: none;
  position: absolute;
  top: 9px;
  left: 12px;
  z-index: 10;
}



@media screen and (max-width: 1000px) { 
  .checkout-form {
    width: 95vw;
  }
  .card {
    width: 300px;
    height: 300px;
  }
  .card-title {
    font-size: 18px;
  }
  .card-description {
    font-size: 14px;
    padding: 8px;
  }
  .outlined-text {
    font-size: 48px;
  }
.content-section {
  flex-direction: column;
  /* color: black; */
  width: 90%;
  gap: 0px;
  margin:0px;
}

.section-image {
  width: 100%;
  margin-bottom: 0px; 
}

.section-image2 {
  width: 100%;
  margin-bottom: 0px; 
}

.text-container {
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
}

.left .section-image {
  margin-right: 0px;
  margin-bottom: 20px;
}

.right .section-image {
  margin-left: 0px;
  margin-top: 20px;
}

.hero-titlew {
  font-size: 23px;
}

.hero-sectionw {
  height: 350px;
}

.section-paragraph{
  font-size: 14px;
}
  
  .RegistrationWhiteBoxCheckout{
    width: 90vw;
  }
  .HeroMainDivPets{
    display: none;
  }
  .HeroMainDiv{
    height: 100%; 
    flex-direction: column;
    padding: 0px 0px 30px 0px;
  }
  .HeroMainDivImage{
    width: 100%;
    margin-top: 40px;
    padding: 0;
  }
  .HeroMainDivImage2{
    display: none;
  }
  .HeroMainDivText_Pets{
    width: 100%;
    padding: 0;
  }
  .HeroMainDivText{
    width: 85%;
    text-align: center;
  }
  .HeroMainDivText2{
    width: 85%;
    text-align: center;
    background: rgba(255,255,255,0.9);
  }
  .RegistrationWhiteBox{
    width: 90vw;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .optionBox{
    flex-direction: column;
    margin-top: 25px; 
    padding: 15px;
    margin-bottom: 18px;
  }
  .optionBoxxy{
    flex-direction: column;
  }
  .welcome-box {
    padding: 30px 15px 0px 15px;
  }
  .Petty{
    display: none;
  }
  .RegistrationFormColumn{
    width: 100%;
  }
  .RegistrationFormColumnPage2{
    width: 100%;
  }
  .postnominal{
    margin-top: 10px;
  }
  .OnlyColumnPage2{
    padding: 0px;
  }
  .LogRegButton{
    flex-direction: column;
  }
  .HealthIssues{
    width: 45%;
  }  
  
  .HealthIssues2{
    width: 90%;
  }  
  .dashboardmain{
    width: 100vw;
    padding-top: 30px;
  }
  .divierwelcome{
    flex-direction: column;
  }
  .divierwelcometext{
    width: 100%;
  }
  .divierwelcomeimage{
    width: 100%;
  }
  .DPhider{
    display: none;
  }
  .SubPatientDiv{
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    padding-left: 15px;
  }
  .AppointmentCodeMain{
    flex-direction: column;
  }
  .AppointmentCodeLeft{
    width: 100%;
  }
  .AppointmentCodeRight{
    width: 100%;
  }
  .hidernav{
    display: none;
  }
  .showerNav{
    display: flex;
  }
  .YourPetsDiv{
    align-items: left;
    justify-content: center;
  }
  .PetDetailDiv{
    height: 200px;
    width: 100%;
  }
  .PetRegDiv{
    width:90%;
  }

  .DocProfDivLeft{
    width: 100%;
  }
  
  .DocProfDivRight{
    width: 100%;
  }
  .imagediv{
    display: none;
  }
  
  .DocProfDivMain {
    flex-direction: column;
  }
  .DocProfDivMainNew {
    flex-direction: column;
  }
  .TiffDiv{
    text-align: center;
  }
  .JohnDiv{
    text-align: center;
  }
  .meettheTeamDivier{
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }
  .DogCatImgHolder{
    width: 100%;
  }
  .DogCatTextHolder{
    width: 100%;
    text-align: center;
  }
  .DogCatImgTextDiv{
    flex-direction: column;
  }
  .fontsizee{
    width: 100%;
    text-align: center;
  }
  .PackageHolder{
    width: 100%;
  }
  .PackageType{
    width: 95vw;
  }
  .PackageHolderText{
    width: 100%;
  }
  .custom-table {
    text-align: center;
  }
  .MaxWidthImage{
    width: 80vw;
  }
  .VetPhoto{
    width: 80vw;
    height: 80vw;
  }
}





.static-text {
  flex-shrink: 0;
  margin-right: 50px;
}

.marquee {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.track {
  display: flex;
  animation: marquee 20s linear infinite;
}

.brand {
  margin-right: 50px;
  white-space: nowrap;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}




/* Home inicio */


@keyframes up-down {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-20px);
  }
}

@-webkit-keyframes up-down {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-20px);
  }
}

.main-banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  height: 980px;
}

.main-banner:after {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-image: url(../../1_MediaAssets/Homepage/shape1.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100%;
  z-index: -1;
}

.main-banner h1 {
  margin-bottom: 50px;
  font-weight: 800;
  line-height: 1.3;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-size: 55px;
}

.main-banner .btn-lg {
  padding: 20px 45px;
}

.main-banner .title-ext {
  margin: 0 0 20px;
  font-size: 21px;
  font-weight: 700;
  display: inline-block;
  padding: 5px 15px;
  background-position: center;
  background-repeat: repeat;
}

.main-banner .title-ext.text-primary {
  color:  #90b7ff!important;
}

.main-banner .title-ext.text-secondary {
  background-image: url(../../1_MediaAssets/Homepage/ext-orange.png);
}

.main-banner .tag-list li {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  padding-right: 5px;
  font-size: 14px;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-weight: 600;
}
.btn-theme {
  background-color: #002366;
  border: none;
  border-radius: 25px;
  color: #fdfdfd;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
  height: 55px;
  line-height: 56px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-out;
  width: 180px;
  cursor: pointer;
}
.main-banner .tag-list li:after {
  content: ",";
  position: absolute;
  bottom: 0;
  right: 0;
  color: #777777;
}

.main-banner .tag-list li.title {
  font-weight: 600;
}

.main-banner .tag-list li.title:after {
  content: none;
}

.main-banner .tag-list li a {
  text-transform: capitalize;
  font-weight: 400;
  color: #ffffff; /* Reemplaza $gray con el valor correspondiente */
}

.main-banner .tag-list li a:hover {
  color: #ffffff; /* Reemplaza $primary con el valor correspondiente */
}

.main-banner .banner-img {
  -webkit-animation: up-down 2.5s infinite alternate;
  animation: up-down 2.5s infinite alternate;
  position: relative;
  margin-right: -160px;
  margin-left: -50px;
}

.main-banner .inner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1200px) {
  .main-banner {
      height: 800px;
  }

  .main-banner .banner-img {
      margin-right: 0;
      margin-left: 0px;
  }

  .main-banner h1 {
      margin-bottom: 30px;
      font-size: 45px;
  }

  .main-banner .title-ext {
      margin: 0 0 10px;
      font-size: 18px;
  }
}

@media (max-width: 992px) {
  .main-banner {
      height: 700px;
  }

  .main-banner h1 {
      margin-bottom: 30px;
      font-size: 36px;
  }

  .main-banner .title-ext {
      font-size: 16px;
      margin: 0 0 15px;
  }
}

@media (max-width: 768px) {
  .responsiveConfirmationPage3{
    width: 90vh!important;
    padding-top: 0!important;
    margin-top: 0!important;
    box-shadow: none!important;
    
}
  .accordionClassParents {
    display: flex;
    flex-direction: column;
  }
  .Pasos-talk-button {
    display: block;
    margin: 0 auto; /* Centrará el botón horizontalmente */
  }

  
  .LeftTexto {

    order: 1; /* Asegura que el párrafo se muestre primero */
    margin: 10px!important;
  }
  .accordionAchicar {
    order: 2;
    margin: 10px!important; /* El acordeón se muestra debajo */
  }

  .main-banner {
      height: 500px;
  }
.responsiveRates {
  width: 100%!important;
}
  .main-banner h1 {
      font-size: 28px;
      margin-bottom: 20px;
  }

  .main-banner .title-ext {
      font-size: 14px;
      margin: 0 0 10px;
      padding: 3px 10px;
  }

  .main-banner .btn-lg {
      padding: 12px 24px;
      font-size: 14px;
  }
}

@media (max-width: 576px) {
  .main-banner {
      height: auto;
      padding-top: 100px;
  }

  .main-banner .banner-img {
      margin: 30px 10px;
  }

  .main-banner .inner-content {
      position: unset;
      transform: unset;
  }
}

@media (max-width: 480px) {
  .main-banner {
      height: auto;
      padding-top: 100px;
  }

  .main-banner .banner-img {
      margin: 30px 10px;
  }

  .main-banner .inner-content {
      position: unset;
      transform: unset;
  }
}

/* shapes **** */
.main-banner .pt-img1 {
  bottom: 35%;
  left: 5%;
}

@media (max-width: 1024px) {
  .main-banner .pt-img1 {
      left: 0%;
      bottom: 25%;
  }
}

.main-banner .pt-img2 {
  top: 24%;
  left: 51%;
}

@media (max-width: 768px) {
  .main-banner .pt-img2 {
      top: 30%;
      left: 55%;
  }
}

.main-banner .pt-img3 {
  top: 23%;
  left: 17%;
}

.main-banner .pt-img4 {
  bottom: 15%;
  left: 41%;
  z-index: 1;
}

@media (max-width: 768px) {
  .main-banner .pt-img4 {
      bottom: 10%;
      left: 45%;
  }
}

.main-banner .pt-img5 {
  bottom: 150px;
  right: 150px;
}
[class*="pt-img"] {
  position: absolute;
  user-select: none;
  z-index: -1;
}

@media (max-width: 576px) {
  [class*="pt-img"] {
      display: none;
  }
}

.animate1 {
  -webkit-animation: up-down 1.5s infinite alternate;
  animation: up-down 1.5s infinite alternate;
}

@media (max-width: 768px) {
  .animate1 {
      width: 80px;
  }
}

.animate2 {
  -webkit-animation: up-down 2s infinite alternate;
  animation: up-down 2s infinite alternate;
}

.animate-rotate {
  -webkit-animation: rotation 20s infinite linear;
  animation: rotation 20s infinite linear;
}

.animate-wave {
  -webkit-animation: left-right 1.5s infinite alternate;
  animation: left-right 1.5s infinite alternate;
}

@media (max-width: 768px) {
  .animate-wave {
      width: 100px;
  }
}

/* rounded animation */
@-webkit-keyframes rotation {
  0% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* move animation */
@-webkit-keyframes move {
  0% {
      -webkit-transform: translate(0);
      transform: translate(0);
  }
  25% {
      -webkit-transform: translate(10px, 10px);
      transform: translate(10px, 10px);
  }
  50% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
  }
  75% {
      -webkit-transform: translate(10px, -5px);
      transform: translate(10px, -5px);
  }
  100% {
      -webkit-transform: translate(0);
      transform: translate(0);
  }
}

@keyframes move {
  0% {
      transform: translate(0);
  }
  25% {
      transform: translate(10px, 10px);
  }
  50% {
      transform: translate(5px, 5px);
  }
  75% {
      transform: translate(10px, -5px);
  }
  100% {
      transform: translate(0);
  }
}

/* mover-animation up-down */
@-webkit-keyframes up-down {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-10px);
  }
}

/* mover-animation left-right */
@-webkit-keyframes left-right {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-10px);
  }
}

@keyframes left-right {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-10px);
  }
}

@keyframes vibrate {
  10%,
  90% {
      transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
      transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
      transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
      transform: translate3d(4px, 0, 0);
  }
}

@keyframes up-down2 {
  0% {
      transform: translate(-50px);
  }
  50% {
      transform: translateX(80px) translateY(-200px);
  }
  100% {
      transform: translate(-50px);
  }
}

/* ACCORDION  */
.second-banner1 {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  background-color: #001844;
}
.accordion-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  padding: 20px;
  max-width: 80%; /* Ajusta el ancho del contenedor principal */
}

.accordion-column {
  flex: 1 1 100%; /* Asegura que cada columna ocupe el 100% del ancho */
  margin: 10px 0;
}



.accordion-summary {
  background-color: #000f2a!important;
}

.accordion-details {
  background-color: #ffffff;
}

.accordion-typography {
  color: #ffffff;
  font-weight: 700 !important;
  font-size: 20px!important;
  font-family: MyFont;
}

.accordion-icon {
  color: #ffffff;
}
/* TESTIMONIAL  */
.second-banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  height: 900px;
}
.testimonial-banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  margin: 30px 80px 0px 80px;
}
.main-banner:after {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-image: url(../../1_MediaAssets/Homepage/shape1.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100%;
  z-index: -1;
}
.second-banner h1 {
  margin-bottom: 50px;
  font-weight: 800;
  line-height: 1.3;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-size: 55px;
}

.second-banner .btn-lg {
  padding: 20px 45px;
}

.second-banner .title-ext {
  margin: 0 0 20px;
  font-size: 21px;
  font-weight: 700;
  display: inline-block;
  padding: 5px 15px;
  background-position: center;
  background-repeat: repeat;
}

.second-banner .title-ext.text-primary {
  color:  #90b7ff!important;
}

/* .second-banner .title-ext.text-secondary {
  background-image: url(../../1_MediaAssets/Homepage/ext-orange.png);
} */

.second-banner .tag-list li {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  padding-right: 5px;
  font-size: 14px;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-weight: 600;
}





.second-banner .inner-content {
  position: absolute;
  left: 50%;
  top: -20%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1200px) {
  .second-banner {
      height: 800px;
  }

  .second-banner .banner-img {
      margin-right: 0;
      margin-left: 0px;
  }

  .second-banner h1 {
      margin-bottom: 30px;
      font-size: 45px;
  }

  .second-banner .title-ext {
      margin: 0 0 10px;
      font-size: 18px;
  }
  .testimonial-banner {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      z-index: 1;
      margin: 30px 15px 0px 15px;
      height: 980px;
  }
}

@media (max-width: 992px) {
  .second-banner {
      height: 700px;
  }

  .second-banner h1 {
      margin-bottom: 30px;
      font-size: 36px;
  }

  .second-banner .title-ext {
      font-size: 16px;
      margin: 0 0 15px;
  }
}

@media (max-width: 768px) {
  .second-banner {
      height: 500px;
  }

  .second-banner h1 {
      font-size: 28px;
      margin-bottom: 20px;
  }

  .second-banner .title-ext {
      font-size: 14px;
      margin: 0 0 10px;
      padding: 3px 10px;
  }

  .second-banner .btn-lg {
      padding: 12px 24px;
      font-size: 14px;
  }
}

@media (max-width: 576px) {
  .second-banner {
      height: auto;
      padding-top: 100px;
  }

  .second-banner .banner-img {
      margin: 30px 10px;
  }

  .second-banner .inner-content {
      position: unset;
      transform: unset;
  }
}

@media (max-width: 480px) {
  .second-banner {
      height: auto;
      padding-top: 100px;
  }

  .second-banner .banner-img {
      margin: 30px 10px;
  }

  .second-banner .inner-content {
      position: unset;
      transform: unset;
  }
}

/* shapes **** */
.second-banner .pt-img1 {
  bottom: 20%;
  left: 15%;
}

@media (max-width: 1024px) {
  .second-banner .pt-img1 {
      left: 0%;
      bottom: 25%;
  } 
}

.second-banner .pt-img2 {
  top: 19%;
  left: 80%;
}

@media (max-width: 768px) {
  .second-banner .pt-img2 {
      top: 30%;
      left: 55%;
  }
}

.second-banner .pt-img3 {
  top: 23%;
  left: 17%;
}

.second-banner .pt-img4 {
  bottom: 15%;
  left: 41%;
  z-index: 1;
}

@media (max-width: 768px) {
  .second-banner .pt-img4 {
      bottom: 10%;
      left: 45%;
  }
}

.second-banner .pt-img5 {
  bottom: 150px;
  right: 150px;
}
.accordion-details {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}



/* Ajustes para la imagen del paciente */
.sc_avatar {
  flex-shrink: 0;
  margin-right: 20px;
}

.sc_image {
  width: 60px;
  height: 60px;
  border-radius: 50%;;
}

/* Contenedor para el texto "Patient" y las estrellas */
.patient-and-starspic {
  display: flex;
  flex-direction: flex;
  align-items: center;
  margin-top: -0px;
}
.patient-and-stars {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 30px 0px 21px 15px;
  font-weight: 900;
}
.patient-label {
  font-size: 16px;
  color: #1b1b1b;
  margin-bottom: 5px;
}

.stars {
  display: flex;
}

.fa-star {
  color: gold;
  margin-right: 2px;
  font-size: 12px;
}
.sc_testimonials_item_text {
  font-family: Arial, sans-serif;
  font-style: italic;
  font-size: 14px;
  color: #292929;
  margin-bottom: 10px;
  min-height: 70px;
}

.sc_testimonials_item_author {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 10px;
}
.sc_testimonials {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 25px;
  border-radius: 15px;
}

.sc_testimonials {
  max-width: 300px; /* Reducir según necesidades */
  margin: auto; /* Centrar la tarjeta en el carrusel */
  padding: 20px; /* Ajustar el espaciado interno */
}

.sc_testimonials_item_content {
  padding: 15px; /* Ajustar el espaciado para el contenido interno */
}
.sc_testimonials_item_content {
  min-height: 140px;  
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}


.slick-slide {
  margin: 15px; /* Añade margen para separar las cards */
}

.slick-list {
  margin-left: -15px; 
  margin-right: -15px; 
}

.testimonialCase {
  background-color: #00102c;
  max-height: 600px;
}
.titlePatients {
  color: white;
  margin-bottom: 50px;
  font-size: 35px;
}

.sc_testimonials_item_date {
  font-size: 12px;
  color: #777;
}
.sc_testimonials_item_name {
  font-size: 12px;
  color: #777;
  font-weight: 700;
}










/* Features with icon */
.icon-features i{
  font-size:35px;
   display: inline-block;
}
.feature-with-icon p {
margin-bottom:2.6em;
}
.feature-with-icon:hover i{
  transform:scale(1.2);
  background-color: #0059ff;
}
.feature-with-icon i{
   transition: all 0.4s ease-in-out;
   text-align: center;
   width: 58px;
   color:#fff;
   background-color: #2b9159;
   border-radius: 50%;
   margin-bottom: 10px;
}
.feature-with-icon {
   margin-bottom: 30px;
}
.feature-with-icon h5{
   margin-bottom: 10px;
}
.section-heading h2:after {
  content: "\f126";
  z-index: 0;
  font-family: "flaticon";
  font-size: 40px;
  z-index: 0;
  transform: rotate(20deg);
  opacity: 0.5;
  display: block;
  animation: move 2s ease-in-out infinite;
}

.mainServices {
  background-color: #001844;
}

.pattern1 {
  background-image: url(../../1_MediaAssets/Homepage/pattern1.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  padding-top: 40px;
  padding-bottom: 70px;

}
.textbenefits {
  color: white;
}
.titlesbenefits {
  color: white;
}
.titlebenefits {
  color: white; 
  font-family: MyFont;
}

.subtitlebenefits {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1.5em;
  color: #f5f5f5; 
}
.pbenefits {
  font-size: 18px;
  color: white;
}

.subtitlebenefits::before,
.subtitlebenefits::after {
  content: "";
  height: 1px;
  background-color: #f3f3f3; 
  margin: 0 10px;
  width: 10%; 
}
.patients-reviews-sec .owl-carousel .owl-item img {
  width: auto;
}

.review-box {
  width: 350px;
  padding: 50px 34px 46px;
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 2px 2px 52px 6px rgb(36 63 250 / 4%);
}

.review-box p {
  margin-bottom: 20px;
}

.patient-details .patient-rating {
  margin-left: 16px;
}

.patient-rating h5 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--primary--color);
}

.patient-rating span {
  font-size: 14px;
}

.patient-rating figure {
  margin-bottom: 12px;
}

.patients-reviews-sec .owl-carousel .owl-dots.disabled {
  display: block;
}

.patients-reviews-sec .owl-carousel .owl-dots {
  text-align: center;
}

.patients-reviews-sec .owl-carousel button.owl-dot {
  border: solid;
  margin: 0 5px;
  height: 13px;
  width: 13px;
  background: #c5ccdc;
  border-radius: 100%;
  border-color: #c5ccdc;
}

.patients-reviews-sec .owl-carousel .owl-nav {
  display: none;
}

.patients-reviews-sec .owl-carousel button.owl-dot.active {
  height: 18px;
  width: 18px;
  background: var(--primary--color);
  border-color: var(--primary--color);
}

.patients-reviews-sec .owl-carousel button.owl-dot:focus {
  outline: none;
}

.patients-reviews-sec .owl-carousel.owl-drag .owl-item.active {
  margin-right: 17px !important;
}

.patients-reviews-sec .owl-carousel .owl-stage {
  width: 8000px !important;
}

.patients-reviews-sec .owl-carousel .owl-stage-outer {
  margin-bottom: 25px;
  box-shadow: 2px 2px 52px 6px rgb(36 63 250 / 4%);
}

.patients-reviews-sec .owl-carousel {
  position: relative;
}

.patients-reviews-sec .owl-carousel::after {
  content: "";
  /* background: url(../images/review-small-img.png) no-repeat right; */
  width: 312px;
  height: 367px;
  position: absolute;
  right: -165px;
  top: -161px;
  z-index: -1;
}

.patients-reviews-sec .review-box:hover {
  cursor: pointer;
  color: #d4dffb;
  background: var(--accent);
}

.patients-reviews-sec .review-box:hover>figure img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(3deg) brightness(107%) contrast(109%);
}

.patients-reviews-sec .review-box:hover .patient-rating h5 {
  color: var(--white-color);
}

/* Home final */

/* Works Inicio  */
@media only screen and (max-width: 1199px) {
  body {
    font-size: 14px;
  }
}



a {
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease-out;
}
a:hover, a:active, a:focus {
  box-shadow: none;
  color: #ffffff;
  outline: none;
}

/*
-----------------------------------------------------------------------
  Common CSS
-----------------------------------------------------------------------
*/

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  margin: 0 auto;
}
@media only screen and (max-width: 1199px) {
  .container {
    padding-bottom: 50px;
    padding-top: 80px;
  }
}


.bg-theme-color {
  background-color: #000000 !important;
}

.bg-color-f2 {
  background-color: #ffffff;
}

.bg-color-f6 {
  background-color: #ffffff;
}
.about-item {
  padding: 35px 0px 35px 0px;
}
.text-color-theme {
  color: #000000;
}


.btn-theme {
  background-color: #002366;
  border: none;
  border-radius: 25px;
  color: #fdfdfd;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
  height: 55px;
  line-height: 56px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-out;
  width: 180px;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .btn-theme {
    font-size: 14px;
    height: 44px;
    line-height: 46px;
    width: 140px;
  }
}
.btn-theme:hover {
  background-color: #007a2f;
  color: #ffffff;
}




/*
-----------------------------------------------------------------------
  About CSS
-----------------------------------------------------------------------
*/

.about-inner-area .container {
  padding-top: 124px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 1199px) {
  .about-inner-area .container {
    padding-top: 74px;
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 991px) {
  .about-inner-area .container {
    padding-top: 78px;
    padding-bottom: 53px;
  }
}

.about-thumb {
  width: 100%;
  padding-top: 50px;
}
@media only screen and (max-width: 991px) {
  .about-thumb {
    margin-bottom: 40px;
  }
}

.about-content {
  margin-top: 10px;
}
@media only screen and (max-width: 991px) {
  .about-content {
    margin-bottom: 30px;
  }
}
.about-content .section-title {
  margin-bottom: 58px;
}
@media only screen and (max-width: 1199px) {
  .about-content .section-title {
    margin-bottom: 18px;
  }
}
.about-content p {
  margin-right: -2px;
}
@media only screen and (max-width: 1199px) {
  .about-content p {
    margin-bottom: 18px;
  }
}
.about-content .btn-theme {
  margin-top: 19px;
}

.about-item-style2 .about-thumb {
  margin-top: -35px;
}
@media only screen and (max-width: 1199px) {
  .about-item-style2 .about-thumb {
    margin-top: 0;
  }
}
.about-item-style2 .about-content {
  margin-right: -5px;
}

.text-color-theme {
    font-weight: 700;
    margin-bottom: 15px;
}

.title {
    color: #002366;
}

.image-container {
    position: relative;
    margin-top: 70px; /* Adjust as needed */
}
  
.main-image {
    width: 100%;
    height: auto;
}
  
.text-overlay {
  position: absolute;
  top: 20%;
  left: 5%; 
  color: white;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  max-width: 30%; 
}
  
.text-overlay h4 {
    font-size: 6rem; 
    margin-bottom: 25px;
    font-weight: 900;
    color: white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}
  
.text-overlay p {
    font-size: 1.2rem; 
    margin-bottom: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}




/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .text-overlay h4 {
      font-size: 2rem;
      max-width: 100%;
  }
  .text-overlay p {
      font-size: 1rem;
  }
}


@media (max-width: 768px) {
    .text-overlay {
        top: 15%;
        left: 5%;
        max-width: 100%;
    }
    .text-overlay h4 {
        font-size: 1.5rem;
    }
    .text-overlay p {
        font-size: 0.875rem;
    }
    .title-main h2 {
      font-size: 2rem;
      line-height: 1.5;
  }
}

@media (max-width: 480px) {
    .text-overlay {
        top: 10%;
        left: 5%;
        padding: 10px;

    }
    .title-main h2 {
      font-size: 2rem;
      line-height: 1.5;
  }
    .text-overlay h4 {
        font-size: 1.25rem;
    }
    .text-overlay p {
        font-size: 0.75rem;
    }
}

.card-container-works {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 40px;
}

.card-works {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    width: 100%;
    max-width: 330px;
    max-height: 330px;
    text-align: center;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    border: none;
}

.card-works:hover {
    transform: translateY(-10px);
}

.card-works img {
    width: 130px;
    height: 130px;
    display: block;
    margin: 0 auto 20px;
}

.card-works h3 {
    margin: 20px 0;
    font-size: 1.3em;
    color: #002366;
}
.card h3 {
  margin: 20px 0;
  font-size: 1.3em;
  color: #002366;
}
.card-works p {
    padding: 0 20px;
    color: #353535;
    flex-grow: 1;
    margin-bottom: 20px;
}
.card p {
  padding: 0 20px;
  color: #353535;
  flex-grow: 1;
  margin-bottom: 20px;
}

.card-works button {
    background-color: #002366;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    border: none;
    padding: 15px 20px;
    margin: 20px auto 0;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card-works button:hover {
    background-color: #2b9159;
}

@media (max-width: 768px) {
    .card-container-works {
        gap: 10px;
        padding: 10px;
    }

    .card-works {
        margin-bottom: 20px;
    }

    .card-works h3 {
        font-size: 1.2em;
        margin: 10px 0;
    }

    .card-works button {
        padding: 10px 15px;
        margin: 10px;
    }

}

@media (max-width: 480px) {
    .card-container-works {
        flex-direction: column;
        align-items: center;
    }

    .card-works {
        width: 90%;
        max-width: none;
    }
}


  .main-content {
    background-color: black;
  }

  h2 {
    font-weight: bolder;
    font-size: 3rem;
  }
  h3 {
    font-weight: bolder;
    font-size: 3rem;
  }
.title-main {
  margin-top: 50px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */

}
.title-main h2 {
  font-size: 5rem;
  line-height: 1.5;

}

.marquee-container {
  display: flex;
  align-items: center;
  background-color: #001130;
  color: #fff;
  padding: 5px 200px 5px 50px;
  overflow: hidden;
  white-space: nowrap;
}

.static-text {
  flex-shrink: 0;
  margin-right: 50px;
  margin-left: 40px;
  font-weight: 900;
  font-size: 19px;
  margin: 25px 50px 25px 40px;
  
}

.marquee {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.track {
  display: flex;
  animation: marquee 40s linear infinite;
}

.brand {
  margin-right: 100px;  /* Aumentado para más espacio entre logos */
  white-space: nowrap;
}

.main-banner-works {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  z-index: 1;
  height: 980px;
}

.main-banner-works:after {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-image: url(../../1_MediaAssets/Homepage/shape1.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100%;
  z-index: -1;
}



.main-banner-works .btn-lg {
  padding: 20px 45px;
}

.main-banner-works .title-ext {
  margin: 0 0 50px;
  font-size: 55px;
  font-weight: 900;
  display: inline-block;
  padding: 5px 15px;
  background-position: center;
  background-repeat: repeat;
  font-family: MyFontBold;
}

.main-banner-works .title-ext.text-primary {
  color:  #ffffff!important;
}

.main-banner-works .title-ext.text-secondary {
  background-image: url(../../1_MediaAssets/Homepage/ext-orange.png);
}

.main-banner-works .tag-list li {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  padding-right: 5px;
  font-size: 14px;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-weight: 600;
}


.main-banner-works h1 {

  margin-bottom: 70px;
  font-weight: 300;
  line-height: 1.3;
  color: #ffffff; /* Reemplaza $dark con el valor correspondiente */
  font-size: 24px;
  font-family: MyFont;

}

.main-banner-works .tag-list li:after {
  content: ",";
  position: absolute;
  bottom: 0;
  right: 0;
  color: #777777;
}

.main-banner-works .tag-list li.title {
  font-weight: 600;
}

.main-banner-works .tag-list li.title:after {
  content: none;
}

.main-banner-works .tag-list li a {
  text-transform: capitalize;
  font-weight: 400;
  color: #ffffff; /* Reemplaza $gray con el valor correspondiente */
}

.main-banner-works .tag-list li a:hover {
  color: #ffffff; /* Reemplaza $primary con el valor correspondiente */
}

.main-banner-works .banner-img {
  -webkit-animation: up-down 2.5s infinite alternate;
  animation: up-down 2.5s infinite alternate;
  position: relative;
  margin-right: -160px;
  margin-left: -50px;
}

.main-banner-works .inner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1200px) {
  .main-banner-works {
      height: 800px;
  }

  .main-banner-works .banner-img {
      margin-right: 0;
      margin-left: 0px;
  }

  .main-banner-works h1 {
      margin-bottom: 30px;
      font-size: 45px;
  }

  .main-banner-works .title-ext {
      margin: 0 0 10px;
      font-size: 55px;
  }
}

@media (max-width: 992px) {
  .main-banner-works {
      height: 700px;
  }

  .main-banner-works h1 {
      margin-bottom: 30px;
      font-size: 36px;
  }

  .main-banner-works .title-ext {
      font-size: 55px;
      margin: 0 0 15px;
  }
}

@media (max-width: 768px) {
  .main-banner-works {
      height: 500px;
  }

  .main-banner-works h1 {
      font-size: 28px;
      margin-bottom: 20px;
  }
  .title-main h2 {
    font-size: 2.5rem;
    line-height: 1.5;
  
  }
  .main-banner-works h1 {
    font-size: 20px;
    margin-bottom: 20px;
}
  h2 {
    font-weight: bolder;
    font-size: 2rem;
}
  .main-banner-works .title-ext {
      font-size: 55px;
      margin: 0 0 10px;
      padding: 3px 10px;
  }

  .main-banner-works .btn-lg {
      padding: 12px 24px;
      font-size: 14px;
  }
}

@media (max-width: 576px) {
  .main-banner-works {
      height: auto;
      padding-top: 100px;
  }

  .main-banner-works .banner-img {
      margin: 30px 10px;
  }

  .main-banner-works .inner-content {
      position: unset;
      transform: unset;
  }
}

@media (max-width: 480px) {
  .main-banner-works {
      height: auto;
      padding-top: 100px;
  }

  .main-banner-works .banner-img {
      margin: 30px 10px;
  }

  .main-banner-works .inner-content {
      position: unset;
      transform: unset;
  }
}

/* shapes **** */
.main-banner-works .pt-img1 {
  bottom: 35%;
  left: 5%;
}

@media (max-width: 1024px) {
  .main-banner-works .pt-img1 {
      left: 0%;
      bottom: 25%;
  }
}

.main-banner-works .pt-img2 {
  top: 24%;
  left: 51%;
}

@media (max-width: 768px) {
  .main-banner-works .pt-img2 {
      top: 30%;
      left: 55%;
  }
  .main-banner-works:after {
    background-image: none;
  }
}

.main-banner-works .pt-img3 {
  top: 23%;
  left: 17%;
}

.main-banner-works .pt-img4 {
  bottom: 15%;
  left: 41%;
  z-index: 1;
}

@media (max-width: 768px) {
  .main-banner-works .pt-img4 {
      bottom: 10%;
      left: 45%;
  }
}

.main-banner-works .pt-img5 {
  bottom: 150px;
  right: 150px;
}
[class*="pt-img"] {
  position: absolute;
  user-select: none;
  z-index: -1;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);  /* Ajuste para repetición continua */
  }
}
/* Works Final  */


.medical-services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0px 15% 0% 15%; /* Añadimos un margen a los lados */

}

.medical-service-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
}

.medical-service-card:hover {
  transform: translateY(-5px);
}

.medical-service-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.medical-service-icon-container {
  background-color: #2b9159;
  border-radius: 50%;
  display: inline-block;
  margin-top: -25px;
  padding: 17px;
  -webkit-box-shadow: 3px 5px 4px -2px rgba(0,0,0,0.49); 
  box-shadow: 3px 5px 4px -2px rgba(0,0,0,0.49);
}

.medical-service-icon {
  width: 40px;
  height: 40px;
}

.medical-service-title {
  font-size: 18px;
  margin: 15px 0 10px;
}

.medical-service-description {
  color: #666;
  font-size: 12px;
  padding: 0 15px;
  text-align: left;
}

.medical-service-link {
  color: #0056b3;
  font-size: 14px;
  margin-bottom: 15px;
  text-decoration: none;
  display: inline-block;
}

/* Responsiveness */
@media (max-width: 1200px) {
  .medical-services-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .medical-services-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .medical-services-container {
    grid-template-columns: 1fr;
  }
}
.allMedical{
  background-color: #000e29;
}



.about-section{
  background-image: url("../../1_MediaAssets/MainPages/about-img.png");
  background-repeat:no-repeat;
  background-position: top right;
  margin-top: 40px;
}
/* testimonials */
.testimonial{
  margin: 0 15px;
  text-align: center;
}
.testimonial .content:before{
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: -0px;
  font-size: 136px;
  z-index: -1;
  opacity: 0.1;
}
.testimonial .content{
  padding: 0px 20px;
  margin-bottom: 15px;
  position: relative;
}
.testimonial .description{
  margin-bottom: 10px;
  font-size: 18px;
}
.testimonial-pic > img{
  max-width: 120px;
  border-radius: 50%;
  border: 5px solid ;
  margin: 10px auto;
}
.testimonial .testimonial-review{
  margin:3px 0 0 15px;
  text-align:center;
}
.testimonial .testimonial-title{
  margin-bottom:0px;
}
.testimonial .testimonial-review span{
  font-size: 16px;
  font-weight: 600;
}
/* number counter */
.counter{
  border-radius: 20px;
  text-align: center;
}
.counter .counter-icon{
  display: block;
  font-size: 50px;
  height: 100px;
  transition: all ease-in-out 0.2s;
  line-height: 100px;
  color: white;
  background: #2b9159;
  display: inline-block;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom:20px;
}
.counter:hover .counter-icon{
  transform:scale(1.2);
}
.counter .counter-value{
  display: block;
  font-family:'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color:#fff;
  letter-spacing: 2px;
}
.counter .title{
  color:#fff;
  font-size: 22px;
  letter-spacing: 0.5px;
  margin: 0;
}
/* Features with icon */
.icon-features i{
 font-size:35px;
  display: inline-block;
}
.feature-with-icon p {
margin-bottom:2.6em;
}
.feature-with-icon:hover i{
 transform:scale(1.2);
}
.feature-with-icon i{
  transition: all 0.4s ease-in-out;
  text-align: center;
  width: 58px;
  color:#fff;
  border-radius: 50%;
  margin-bottom: 10px;
}
.feature-with-icon {
  margin-bottom: 30px;
}
.feature-with-icon h5{
  margin-bottom: 10px;
}
.page {
  background-color: #000022;
}

.counter-calltoaction{
  background-image: url("../../1_MediaAssets/MainPages/counterbg.png");
  background-position: 85% center;
  background-size:cover;
}
@media (min-width: 992px) {
  .bg-fixed {
      background-attachment: fixed !important;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1320px;
  }
}
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* 0.5);
  padding-left: calc(var(--bs-gutter-x)* 0.5);
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
.overlay {
  padding-top: 200px;
  padding-bottom: 120px;
}
.block-padding {
    padding-top: 90px;
    padding-bottom: 90px;
}
.titleGoal {
  font-size: 4em;
  margin-bottom: 35px;
  color: white;
}
.textGoal {
  font-size: 1.3em;
  color: white;
  margin-bottom: 30px;
}

/* SECTION FIND A VET  */







:root {
  --side-margin: 10px; /* Puedes ajustar este valor según lo necesites */
  --card-width: 445px; /* Puedes ajustar este valor según lo necesites */
}

.FindAVet {
  display: flex;
  justify-content: center;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
  margin-bottom: 100px;
  min-height: 70vh;
}

.vet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--card-width), 1fr));
  gap: 20px;
  width: 100%;
  max-width: calc(3 * var(--card-width) + 2 * 20px); /* Ajuste automático del ancho máximo */
}

.vet-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.vet-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(253 , 253, 253);
}

.vet-prices-container {
  background-color: #E6EEF8;
  font-size: 16px; /* Color azul claro */
  border-top: 1px solid #e5e7eb; /* Borde superior para separar las secciones */
  padding: 15px;
  color: #11294C;
  margin-top: 15px;
  border-radius: 0 0 8px 8px;
  margin: 15px -15px -15px -15px; /* Redondear solo la parte inferior */
}
.vet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vet-arrow {
  font-size: 18px;
  margin-top: 30px;
  margin-right: 5px;
  color: #757575; /* Color de la flecha */
}
.vet-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.vet-info {
  flex: 1;
}

.nameVet {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.titleVet {
  font-size: 14px;
  color: #757575;
  margin: 5px 0;
}

.vet-rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #757575;
}

.vet-rating .star {
  color: #002366;
  margin-right: 5px;
}

.vet-rating .rating {
  font-weight: bold;
  margin-right: 5px;
}

.vet-rating .reviews {
  color: #757575;
}

.license-verified {
  text-align: left;
  margin-top: 10px;
}

.license-badge {
  display: inline-block;
  background-color: #F0FDF4;
  color: #15803D;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  border: 0.5px solid rgb(187 247 208);
}

.vet-prices {
  margin: 10px 0;
}

.timeslot-button {
  display: flex;
  width: 100%;
  padding: 17px;
  margin: 5px 0;
  justify-content: space-between;
  background-color: #FFF;
  border: 1px solid rgb(34 80 130);
  border-radius: 8px;
  font-size: 14px;
  color: rgb(1, 0, 66);
  cursor: pointer;
  text-align: left;
  margin-bottom: 15px;
}

.timeslot-button:hover {
  display: flex;
  width: 100%;
  padding: 17px;
  margin: 5px 0;
  justify-content: space-between;
  background-color: #efefef;
  border: 1px solid rgb(34 80 130);
  border-radius: 8px;
  font-size: 14px;
  color: black;
  cursor: pointer;
  text-align: left;
  margin-bottom: 15px;
}

.more-timeslots {
  text-align: center;
  margin-top: 10px;
}

.more-timeslots a {
  font-size: 14px;
  color: #00796b;
  text-decoration: none;
}

.more-timeslots a:hover {
  text-decoration: underline;
}


.license-verified {
  position: relative;
  display: inline-block;
}

.license-verified .tooltip-text {
  visibility: hidden;
  width: 280px;
  background-color: rgba(51, 51, 51, 0.97);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Posiciona el tooltip encima del badge */
  left: 13%;
  margin-left: -125px; /* Centra el tooltip */
  opacity: 0;
  transform: translateY(10px); /* Posición inicial para la animación */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Animación suave */
}

.license-verified .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Flecha apuntando hacia abajo */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.license-verified:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); /* Posición final para la animación */
}
/* SECTION PRICING IN HOME */

.PricingSection-container {
  background-color: #000231;
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PricingSection-header {
  text-align: center;
  color: white;
  margin-bottom: 20px;
  width: 100%;
}

.PricingSection-header h2 {
  font-size: 27px;
  margin-bottom: 20px;
  font-weight: 900;
}


.PricingSection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.PricingSection-plans {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.PricingSection-plan {
  background-color: white;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PricingSection-recommended {
  border: 2px solid #2b9159;
}

.PricingSection-recommendedLabel {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #2b9159;
  padding: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.PricingSection-plan h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.PricingSection-plan p {
  margin: 10px 0;
}

.PricingSection-selectPlan,
.PricingSection-talkToVet {
  padding: 10px 30px;
  margin-right: 30px;
  background-color: #2b9159;
  color: white;
  cursor: pointer;
  font-size: 14px; 
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
}

.PricingSection-selectPlan:hover,
.PricingSection-talkToVet:hover {
  background-color: #000f1f;
  transform: translateY(-2px);
  font-weight: 900;
  color: white;
}
.PricingSection-details {
  text-align: left;
  color: white;
  width: 100%;
  max-width: 400px;
  margin-top: 39px;
  
}

.PricingSection-details p {
  font-size: 18px;
}

.PricingSection-features {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  justify-content: center; /* Centra los elementos horizontalmente */
}

.PricingSection-features li {
  width: 46%; /* Ajusta el ancho de cada elemento para que quepan 3 por fila */
  margin: 10px; /* Margen para separar los elementos */
  display: flex;
  font-size: 15px;
  align-items: center; /* Alinea el icono y el texto verticalmente */
}
.PricingSection-features li i {
  margin-right: 10px; /* Espacio entre el icono y el texto */
}
.PricingSection-joinNow {
  background-color: #2b9159;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 25px;
}

.PricingSection-joinNow:hover {
  background-color: #29ff89;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-radius: 25px;
}

.PricingSection-header p {
  font-size: 20px;
  font-weight: 400;
}
@media (min-width: 768px) {
  .PricingSection-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
  }

  .PricingSection-plans {
    flex-direction: column;
    max-width: 45%;
    margin-right: 20px;
  }

  .PricingSection-details {
    max-width: 45%;
    text-align: left;
  }

  .PricingSection-joinNow {
    width: auto;
    align-self: flex-start;
  }

  .PricingSection-details p,
  .PricingSection-header {
    text-align: left; 
  }

}

@media (max-width: 768px) {
  .nondisplaydonationinfo {
    display: none;
  }
  .checkout-form {
    width: 100%!important;
    padding: 0!important;
    border: none!important;
    box-shadow: none!important;
  }
  .mobileresponsiveDonationBox {
    width: 100%!important;
    padding: 0!important;
  }
  .PricingSection-content {
    flex-direction: column;
    align-items: center;
  }
  .PricingSection-features li {
    width: 100%; /* En pantallas más pequeñas, los elementos ocuparán toda la fila */
  }
  .PricingSection-header {
    order: -1; 
  }

  .PricingSection-header,
  .PricingSection-plans,
  .PricingSection-details {
    max-width: 100%;
  }
  .PricingSection-header h2, p{
    text-align: left; 
  }
  .PricingSection-joinNow {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .PricingSection-header h2 {
    font-size: 20px;
  }

  .PricingSection-details p,
  .PricingSection-features li {
    font-size: 16px;
  }
}
.fa-circle-check {
  color: #49ac76;
}

/* STEPS CONTAINER  */

.Pasos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px; /* Ajuste de padding para crear espacio alrededor */
}

.Pasos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  border-radius: 15px;
  max-width: 1200px;
  width: 100%; /* Aseguramos que el contenedor use el ancho completo posible */
}

.Pasos-content {
  max-width: 45%;
  flex: 1;
  min-width: 300px;
  margin-bottom: 20px;
}

.Pasos-title {
  color: #00163c;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 30px;
}

.Pasos-subtitle {
  color: #4a5568;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.Pasos-talk-button {
  padding: 10px 30px;
  margin-right: 30px;
  background-color: #2b9159;
  color: white;
  cursor: pointer;
  font-size: 14px; 
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.Pasos-talk-button:hover {
  background-color: #00072c;
  color: white;
  font-weight: 900;
}

.Pasos-steps {
  margin-top: 30px;
}

.Pasos-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.Pasos-step-number {
  background-color: #2b9159;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  margin-right: 15px;
  flex-shrink: 0; /* Prevenir la deformación */
}

.Pasos-step-title {
  margin: 0;
  font-size: 1.2em; 
  color: #00163c;
}

.Pasos-step-description {
  margin: 5px 0 0;
  color: #4a5568;
  font-size: 14px;
}

.Pasos-image-container {
  max-width: 50%;
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px; /* Margen en ambos lados */
  background-color: #dae5ff;
  opacity: 1;
  border-radius: 30px;
}

.Pasos-image {
  width: 75%;
  height: auto;
  max-width: 305px;/* Limitar el tamaño máximo de la imagen */

}
/* For Vets page */

.ForVets-app {
  font-family: Arial, sans-serif;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('/src/components/1_MediaAssets/VetsandTechs/bgVetsandTech.png') no-repeat center center/cover;

}

.ForVets-hero {
  padding: 50px;
  border-radius: 10px;
  text-align: left;
  max-width: 800px;
  margin-top: 60px;
}

.ForVets-content h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 20px;
  color: white;
}

.ForVets-highlight {
  color: #002366;
  font-weight: 900;
}

.ForVets-content p {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: white;
  font-weight: 600;
}

.ForVets-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.ForVets-create-account, .ForVets-login {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ForVets-create-account {
  background-color: #002366;
  color: #fff;
  border-radius: 20px;
  font-weight: 700;
}

.ForVets-login {
  background-color: #fff;
  border: 2px solid #002366;
  color: #002366;
  font-weight: 700;
  border-radius: 20px;
}

.ForVets-create-account:hover {
  background-color: #001a4d;
  color: white;
}

.ForVets-login:hover {
  background-color: #58b86c;
  border: 2px solid #58b86c;
}

.ForVets-expand-clinic {
  color: #0073e6;
  text-decoration: none;
}

.ForVets-expand-clinic:hover {
  text-decoration: underline;
}

/* New Container */
.NewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f0f0f0;
}

.textitleVets {
  text-align: center;
}

/* VP Section */
.vp-container {
  font-family: Arial, sans-serif;
  color: #ffffff;
  padding: 2em;
  margin: 0 auto;
  max-width: 1200px;
}
.fatherVP {
  background-color: #002366;
}
.vp-header {
  text-align: center;
  margin-bottom: 2em;
}

.vp-title {
  margin-top: 0.5em;
  font-size: 3.5em;
  font-weight: bold;
  margin-bottom: 0.2em;
}

.vp-subtitle {
  font-size: 1.2em;
  margin-bottom: 3em;

}

.vp-create-account-button {
  padding: 0.75em 2em;
  margin-bottom: 2em;
  background-color: #2b9159;
  color: white;
  border: none;
  border-radius: 45px;
  font-size: 1.5em;
  font-weight: 900;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vp-create-account-button:hover {
  background-color: #060058;
  color: white;
}

.vp-main-content {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  max-width: 1200px;
  margin: 0 auto;
}

.vp-info-section {
  flex: 1;
  max-width: 600px;
  text-align: left;
  border-bottom: 4px solid #2b9159;
}

.vp-info-block {
  margin-bottom: 2em;
}

.vp-info-title {
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.vp-info-text {
  font-size: 1.5em;
  line-height: 1.5;
}

.vp-image-container {
  flex: 1;
  max-width: 400px;
  display: flex;
  justify-content: center;
}

.vp-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* VW Section */
.vw-container {
  font-family: Arial, sans-serif;
  color: white;
  background-color:#00021d ;
  padding: 2em;
  text-align: center;
}

.vw-header {
  margin-bottom: 2em;
}

.vw-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 0.3em;
}

.vw-subtitle {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #73ffb2;
  font-weight: 400;
  
}

.vw-content {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 3px solid #2b9159;
  padding: 30px;
}

.vw-image-container {
  flex: 1;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

.vw-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.vw-info-section {
  flex: 1;
  max-width: 600px;
  text-align: left;
}

.vw-info-block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2em;
}

.vw-icon {
  width: 40px;
  height: 40px;
  margin-right: 1em;
}

.vw-info-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.vw-info-text {
  font-size: 1em;
  line-height: 1.5;
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #58f69f;
}

@media (max-width: 768px) {
  .vp-container, .vw-container {
    padding: 1em;
  }

  .vp-header, .vw-header {
    text-align: center;
  }

  .vp-title, .vw-title {
    font-size: 2em;
  }

  .vp-subtitle, .vw-subtitle {
    font-size: 1em;
  }

  .vp-main-content, .vw-content {
    display: block;
  }

  .vp-info-section, .vw-info-section {
    margin-bottom: 2em;
  }

  .vp-image-container, .vw-image-container {
    text-align: center;
  }
  .GetAPrescription-title {
    margin-top: 30px!important;
  }
  .vp-image, .vw-image {
    max-width: 100%;
    height: auto;
  }
  .rexVetsbav-container {
    width: 100%!important;
    margin: 0;
    padding: 10px!important;
  }
}
/* Choose your membership section modifications */
.veterinary-container {
  position: relative;
  width: 100%;

/* Ajustado para ser más pequeño */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 85px;
  overflow: hidden;
}

.veterinary-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.veterinary-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.veterinary-content {
  position: relative;
  z-index: 2;
  text-align: left;
  color: white;
  max-width: 700px;
  flex: 1;
  margin-right: 80px; /* Añade este margen para separar del chat */
}

.veterinary-title {
  font-size: 3rem;
  margin-bottom: 10px;
}

.veterinary-description {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.veterinary-subscribe-button {
  background-color: #2b9159;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}
.veterinary-subscribe-button:hover {
  background-color: #002366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;
}

.veterinary-chat-container {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin-left: 20px;
  flex: 1;
  margin-left: 50px; /* Añade este margen para separar del contenido */
}

.veterinary-chat-message {
  display: flex;
  align-items: center;
}

.veterinary-chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.veterinary-chat-bubble {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border-radius: 20px 20px 20px 0;
  font-size: 19px;
  max-width: 270px;
  margin-left: 10px;
}

.veterinary-chat-message-left .veterinary-chat-avatar {
  margin-right: 10px;
}

.veterinary-chat-message-right .veterinary-chat-bubble {
  background-color: rgba(68, 85, 255, 0.9);
  margin-left: auto;
  border-radius: 20px 20px 0px 20px;
  margin-right: 10px;
}

.veterinary-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto; 
  padding-top: 40px;
  padding-bottom: 40px;
   /* Ajustado para reducir la altura */
}

/* Support page */

/* Estilos generales */
.help-support-component {
  display: flex;
  align-items: center;
  background: url('/src/components/1_MediaAssets/Homepage/bgSupport.jpg') no-repeat center center/cover;
  color: #fff;
  padding: 20px;
  height: 70vh;
  margin-top: 80px;
}

.help-support-content {
  max-width: 50%;
  margin-left: 20px;
}

.help-support-title {
  font-size: 4.5rem;
  margin-bottom: 1rem;
  margin-left: 200px;
}

.help-support-text {
  margin-left: 200px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 800px;
}

.help-support-link {
  color: #a7daff;
  text-decoration: none;
  font-weight: 900;
}

.help-support-link:hover {
  color: rgb(153, 255, 153);
}

.support-options {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 200px;
  margin-right: 200px;
  padding: 40px 20px;
  margin-top: 50px;
}

.optionsbg {
  background-color: #fafaff;
}

.support-option-height {
  min-height: 200px;
}

.support-option {
  text-align: center;
  max-width: 400px;
  margin: 0 20px;
}

.support-icon {
  font-size: 3rem;
  color: #002366;
  margin-bottom: 10px;
}

.support-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.support-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.support-button {
  background-color: #002366;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.support-button:hover {
  background-color: #09002c;
}

.connect-options {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
}

.connect-title {
  font-size: 1.5rem;
  color: #2d2d2d;
  margin-bottom: 30px;
  margin-top: 20px;
}

.connect-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.connect-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #002366;
  border: 2px solid #002366;
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 900;
  font-size: 1.1rem;
  margin-bottom: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connect-button i {
  margin-right: 8px;
  font-size: 1.2rem;
}

.connect-button:hover {
  background-color: #ffffff;
  border: 2px solid #00112f;
}

/* Media queries para responsividad */
@media (max-width: 1200px) {
  .help-support-title, .help-support-text {
    margin-left: 100px;
  }

  .support-options {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 992px) {
  .help-support-title {
    font-size: 3.5rem;
    margin-left: 50px;
  }

  .help-support-text {
    font-size: 1rem;
    margin-left: 50px;
  }

  .support-options {
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
  }

  .support-option {
    max-width: 100%;
    margin: 20px 0;
    padding-bottom: 30px;
    border-bottom: 2px solid #002366;
  }

  .connect-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .help-support-component {
    flex-direction: column;
    height: auto;
    
  }
  .ForVets-content h1 {
    font-size: 1.5rem;
    
  }
  .ForVets-content p {
    font-size: 1.2rem;
  }
  .help-support-content {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .help-support-title, .help-support-text {
    margin: 0;
    max-width: 100%;
  }

  .support-options {
    margin: 20px 0;
    padding: 20px;
  }

  .connect-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .help-support-title {
    font-size: 2.5rem;
  }

  .help-support-text {
    font-size: 0.9rem;
  }

  .support-button, .connect-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
/* DONATE SECTION */
.help-donate-component {
  display: flex;
  align-items: center;
  background: url('/src/components/1_MediaAssets/Homepage/bgDonate.jpg') no-repeat center center/cover;
  color: #fff;
  padding: 20px;
  height: 70vh;
}

.help-donate-content {
  max-width: 50%;
  margin-left: 20px;
}

.help-donate-title {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  margin-left: 200px;
}

.help-donate-text {
  margin-left: 200px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 800px;
}

.help-donate-link {
  color: #a7daff;
  text-decoration: none;
  font-weight: 900;
}

.help-donate-link:hover {
  color: rgb(153, 255, 153);
}

.optionsbg {
  background-color: #fafaff;
}

.donate-icon {
  font-size: 3rem;
  color: #002366;
  margin-bottom: 10px;
}

.donate-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.donate-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.donate-button {
  background-color: #002366;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.donate-button:hover {
  background-color: #09002c;
}


@media (max-width: 1200px) {
  .help-donate-title, .help-donate-text {
    margin-left: 100px;
  }
}

@media (max-width: 992px) {
  .help-donate-title {
    font-size: 3.5rem;
    margin-left: 50px;
  }

  .help-donate-text {
    text-align: center;
    font-size: 1rem;
    margin-left: 50px;
    font-weight: 300;
  }
}

@media (max-width: 768px) {
  
  .help-donate-component {
    justify-content: center;
  }

  .help-donate-component {
    flex-direction: column;
    height: 30vh;
  }

  .help-donate-content {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .help-donate-title, .help-donate-text {
    margin: 0;
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .help-donate-title {
    font-size: 2.5rem;
  }

  .help-donate-text {
    font-size: 0.9rem;
  }

  .donate-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
.donate-section-container {
  display: flex;
  width: 100vw;
  flex-direction: row;
  align-items: stretch;
}

.donate-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.donate-image-container {
  width: 100%;
  padding-bottom: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.5s ease-in-out, filter 0.3s ease-in-out;
  opacity: 0;
  cursor: pointer;
}

.donate-image-container:hover {
  filter: brightness(1.2);
}

.donate-text-container {
  width: 50%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  transition: transform 0.5s ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0;
  font-size: 1.2rem;
  line-height: 1.6;
}

.donate-text-container h1 {
  color: #002366;
}

.donate-section-container.scrolled .donate-image-container,
.donate-section-container.scrolled .donate-text-container {
  transform: translateX(0);
  opacity: 1;
}

.donate-section-container .donate-image-container {
  transform: translateX(-100%);
}

.donate-section-container .donate-text-container {
  transform: translateX(100%);
}

.btnDonate {
  background-color: #2b9159;
  margin-top: 20px;
  margin-left: 200px;
}

@media (max-width: 1000px) {
  .btnDonate {
    margin-left: 0px;
  }

  .donate-text-container {
    text-align: center;
  }

  .donate-text-container p {
    text-align: center;
    font-size: 13px;
  }

  .donate-text-container h1 {
    text-align: center;
    font-size: 18px;
  }

  .hideme {
    display: none;
  }

  .donate-section-container {
    flex-direction: column;
  }

  .donate-image-wrapper {
    width: 100%;
    min-height: 10%;
    padding: 20px 10%;
  }

  .donate-image-container,
  .donate-text-container {
    width: 100%;
    min-height: auto;
  }

  .donate-image-container {
    padding-bottom: 100%; 
    transform: translateX(0);
  }

  .donate-text-container {
    transform: translateX(0);
  }

  .donate-section-container .donate-text-container {
    order: 2;
  }

  .donate-section-container .donate-image-wrapper {
    order: 1;
  }

  .donate-section-container:nth-of-type(even) .donate-text-container {
    text-align: right;
  }
}

.videoDivDonate {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  padding: 50px 0;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: black;
  background-image: url('../../1_MediaAssets/MainPages/DonateBG.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.videoDivDonate p, .videoDivDonate h1 {
  z-index: 2;
  color: white;
  max-width: 1200px;
  text-align: center;
}

.videoDivDonate video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: cover;
  opacity: 0.4;
}






/* Health solution */
.rex-vets-container {
  padding-top: 40px;
  margin-top: 0rem;
  text-align: center;
  padding-left: 0rem; /* Reduce el margen lateral izquierdo */
  padding-right: 0rem;
}

.rex-vets-title {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
}

.rex-vets-card {
  background-color: #f8f8f8;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  margin-top: 30px;
  margin-bottom: 60px;
}


.rex-vets-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.rex-vets-card-title {
  background-size: cover;
  background-position: center;
  text-align: center;
  min-height: 250px;

}

.rex-vets-subtitle {
  margin-bottom: 0;
  font-weight: bold;
  color: #fff;
  min-height: 94px;
  font-size: 15px;
}

.rex-vets-text {
  color: #777;
  line-height: 1.6;
  text-align: left;
  padding: 1rem;
  min-height: 200px;
}

.rex-vets-text i {
  color: #4caf50;
  margin-right: 8px;
}
.AllofCards {
  background-color: #00072c;
}
.tittleHealthSolution {
  color: white;
  margin-bottom: 30px;
}




.GetAPrescription-container {
  font-family: Arial, sans-serif;
  background: #002366 ;
}

.GetAPrescription-background {
  background-image: url('../Homepage/bgPrescribe.png');
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  color: white;
  text-align: left;
  margin-top: 70px;
  min-height: 650px;
}

.GetAPrescription-content {
  max-width: 740px;
  margin-left: 40px;
}

.GetAPrescription-title {
  font-size: 3.3em;
  margin-top: 120px;
  margin-bottom: 10px;
}

.GetAPrescription-description {
  font-size: 1.4em;
  margin-bottom: 45px;
  font-weight: 700;
  margin-top: 10px;
  
}



.GetAPrescription-button {
  background-color: #2b9159;
  color: white;
  border: none;
  padding: 17px;
  font-size: 1.1em;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 40px;
  font-weight: 700;
}

.GetAPrescription-button:hover {
  background-color: #002366;
  color: white;
}

.GetAPrescription-features {
  display: flex;
  justify-content: space-around;
  margin: 10px 230px 0px 230px;
  padding: 20px;
  color: #1b1b1b;
}

.GetAPrescription-feature {
  text-align: center;
  flex: 1;
  padding: 20px;
}

.GetAPrescription-feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  background-color: #000e27 ;
  border-radius: 50%;
}

.GetAPrescription-feature i {
  font-size: 2em;
  color: #ffffff ;
}

.GetAPrescription-feature p {
  font-size: 1.3em;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}
.RexVetsShop-container {
  background-color: #000e27;
  padding: 60px 30px;
}

.RexVetsShop-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1350px;
  margin: auto;
}

.RexVetsShop-text {
  max-width: 50%;
}

.RexVetsShop-title {
  font-size: 3em;
  margin-bottom: 30px;
  color: white;
}

.RexVetsShop-description {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: white;
}

.RexVetsShop-button {
  background-color: #34784a;
  color: white;
  border: none;
  padding: 20px;
  font-size: 1.3em;
  border-radius: 40px;
  cursor: pointer;
  font-weight: 700;
}

.RexVetsShop-button:hover {
  background-color: #002366;
}

.RexVetsShop-image-container {
  margin-left: 30px;
}

.RexVetsShop-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.RexVetsPhar-info-container {
  background-color: #ffffff;
  padding: 60px 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.RexVetsPhar-info-content {
  display: flex;
  max-width: 1200px;
  align-items: center;
}

.RexVetsPhar-info-image-container {
  margin-right: 30px;
}

.RexVetsPhar-info-image {
  width: 400px;
  height: 400px;
  margin-right: 35px;
  object-fit: cover;
  border-radius: 15%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.RexVetsPhar-info-text {
  max-width: 50%;
}

.RexVetsPhar-info-title {
  font-size: 3em;
  margin-bottom: 30px;
}

.RexVetsPhar-info-list {
  list-style: none;
  padding: 0;
}

.RexVetsPhar-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.RexVetsPhar-info-icon {
  background-color: #00142a;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.RexVetsPhar-info-description {
  font-size: 1.3em;
  color: #333;
}

/* NavBar new Parents Dashboard */


.DashboardGab-navbar {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px 20px;
  min-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.DashboardGab-navbar__icons {
  display: flex;
  align-items: center;
}

.DashboardGab-navbar__item {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.DashboardGab-icon-rocket::before,
.DashboardGab-icon-cart::before,
.DashboardGab-icon-bell::before,
.DashboardGab-icon-globe::before,
.DashboardGab-icon-caret-down::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 20px;
  color: #6c757d;
}

.DashboardGab-icon-rocket::before {
  content: "\f135"; /* rocket icon */
}

.DashboardGab-icon-cart::before {
  content: "\f07a"; /* cart icon */
}

.DashboardGab-icon-bell::before {
  content: "\f0f3"; /* bell icon */
}

.DashboardGab-icon-globe::before {
  content: "\f0ac"; /* globe icon */
}

.DashboardGab-icon-caret-down::before {
  content: "\f0d7"; /* caret down icon */
  font-size: 12px;
  margin-left: 5px;
}

.DashboardGab-navbar__notifications-dropdown,
.DashboardGab-navbar__language-dropdown,
.DashboardGab-navbar__profile-dropdown {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 10;
  width: 200px;
}

.DashboardGab-navbar__item:hover .DashboardGab-navbar__notifications-dropdown,
.DashboardGab-navbar__item:hover .DashboardGab-navbar__language-dropdown,
.DashboardGab-navbar__profile:hover .DashboardGab-navbar__profile-dropdown {
  display: block;
}

.DashboardGab-navbar__notifications-dropdown span,
.DashboardGab-navbar__language-dropdown span,
.DashboardGab-navbar__profile-dropdown span {
  display: block;
  font-weight: bold;
}

.DashboardGab-navbar__notifications-dropdown p,
.DashboardGab-navbar__language-dropdown p,
.DashboardGab-navbar__profile-dropdown small {
  display: block;
  margin-bottom: 10px;
  color: #666;
}

.DashboardGab-navbar__language-dropdown img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.DashboardGab-navbar__profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dee2e6;
  padding: 5px;
  cursor: pointer;
}

.PackageType {
  position: relative;
}

.recommended-label {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #efb810;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
}


.accordion-item {
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.accordion-button {
  background: #00182f;
  color: white;
  font-weight: 700;
}
.accordion-body {
  background-color: #002c58;
  padding: 15px;
  font-size: 16px;
  color: white;
}

.accordion-button:not(.collapsed) {
  background-color: #00152b;
  color: rgb(255, 255, 255);
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button::after {
  filter: invert(100%);
}
.accordionAchicar {
  max-width: 450px;
  margin-right: auto;
  margin-left: 40px;
}
.accordionClassParents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;

}
.LeftTexto {
  font-size: 26px;
  max-width: 500px;
  margin-left: auto;
  margin-right: 40px;
}
.Accordions-andText {
  background-color: #002261;
}

.vet-selection-container {
  font-family: Arial, sans-serif;
  margin-top: 120px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 14%;
  margin-left: 14%;

}


.vet-info h2 {
  font-size: 1.5em;
  margin: 0;
}

.vet-info p {
  font-size: 1em;
  margin: 5px 0;
}

.rating i {
  color: #002366;
}

.rating a {
  color: #3498db;
  text-decoration: none;
}

.license-verified {
  color: #27ae60;
  font-weight: bold;
  margin-top: 5px;
}

.vet-description {
  margin-top: 10px;
  font-size: 0.9em;
}

.show-profile {
  color: #3498db;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}

.species-treated {
  margin-top: 20px;
}

.species-treated h4 {
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: bold;
}

.species-icons {
  display: flex;
  gap: 20px;
}

.species {
  background-color: #ffffff;
  padding: 20px 40px 20px 40px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  border: 1px solid rgb(244, 244, 244);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
}

.species i {
  font-size: 1.5em;
}

.areas-of-interest {
  margin-top: 20px;
}

.areas-of-interest h4 {
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: bold;
}

.interest-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.interest-list li {
  font-size: 0.9em;
}
.fa-id-card {
  color: #002366;
}

.return-link {
  font-size: 20px;
}
.return-link:hover {
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.coughing-text h2 {
  margin-top: 30px;
}
.appointmentStyling {
  
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, .1);
  border-radius: 16px;
  overflow-y: hidden; /* Asegúrate de que no se desborde contenido fuera de las esquinas */
  overflow-x: hidden;
}
.vet-appointment h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  margin: 20px;
}

.time-slots {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 20px;
}

.time-slots li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.time-slots li:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.price {
  font-weight: bold;
}

.show-more {
  display: block;
  margin-top: 10px;
  color: #1d1d1d;
  text-decoration: none;
  font-size: 0.9em;
  transition: color 0.3s;
  font-weight: 700;
}

.show-more:hover {
  color: #000000;
}

.total-price {

  margin-top: 20px;
  background-color: #fdfdfd;
  padding: 15px;
  border-radius: 5px;
  font-size: 0.9em;
  transition: box-shadow 0.3s;
  color: black;
}

.total-price:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.withColor {background-color: #001d53e5;
width: 100%;
padding: 20px;
color: white;
margin-top: 25px;
box-shadow: 0 0 10px 3px rgba(0, 0, 0, .1);
}
.total-price p {
  margin: 10px 0;
}

.total {
  font-weight: bold;
  font-size: 1em;
  padding: 20px 20px 0px 20px;
  margin-top: 10px;
}
.taxestext {
  font-size: 12px;
  padding-left: 20px;
}

.amount {
  font-size: 1.2em;
  color: #27ae60;

}

.continue-booking {
  display: block;
  text-align: center;
  background-color: #002366!important;
  color: white;
  padding: 10px;
  border-radius: 35px;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 10px auto 0px auto; /* Cambiado a auto para centrar horizontalmente */
  width: 92%;
}
.continue-booking:hover {
  background-color: #27ae60!important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */

.anchorAnnual {
  color: #2b9159;
}

.vet-areas-reviews {
  margin-top: 40px;
  font-family: Arial, sans-serif;
}


.vet-areas-of-interest h4,
.vet-licenses h4,
.vet-reviews h4 {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-weight: bold;
}

.vet-interest-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.vet-interest-list li {
  font-size: 0.9em;
}

.vet-show-all-button {
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 0.9em;
  color: #333;
  border: 2px solid #101010;
  border-radius: 50px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.vet-show-all-button:hover {
  background-color: #f0f0f0;
  border-color: #e3e3e3;
  border: 2px solid rgba(255, 255, 255, 0);
  color: #000;
}

.vet-licenses p {
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

.vet-licenses i {
  margin-right: 8px;
  color: #002366;
}

.vet-reviews h4 {
  display: flex;
  align-items: center;
}

.vet-reviews h4 i {
  margin-right: 10px;
  color: #002366;
}

.vet-reviews-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-right: 100px;
  
}

.vet-review {
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  font-size: 0.9em;
  border: 1px solid rgb(239, 239, 239);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vet-review-author {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.vet-review-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
}

.vet-review-details p {
  margin: 0;
  font-size: 0.85em;
  color: #0a0a0a;
}

.vet-review-details p:first-child {
  font-weight: bold;
  color: #333;
}
.colorCheck {
  color: #002366
}

.appointments1-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}


.appointments1-header h2 {
  font-size: 1.5em;
  margin: 0;
  display: flex;
  align-items: center;
}

.appointments1-header h2 i {
  margin-right: 10px;
  color: #6c757d;
}















.appointments1-content {
  text-align: center;
}

.appointments1-big-icon {
  font-size: 8em;
  color: #e2e2e2;
  margin-bottom: 20px;
}

.appointments1-book-button {
  padding: 10px 20px;
  color: white;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.appointments1-book-button i {
  margin-left: 10px;
}

.appointments1-book-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {

  .videoDivDonate {
    background-attachment:scroll;
  }


  .reducemobFont {
    font-size: 20px;
  }
  .GetAPrescription-features {
    flex-direction: column;
    margin: 10px 20px 0px 20px;
    padding: 20px;
  }

  .GetAPrescription-feature {
    padding: 20px 0;
    margin-bottom: 20px;
  }
.titleWeAim {
  text-align: center;
  margin: 0!important;
  margin-bottom: 15px!important;
}
  .GetAPrescription-feature-icon {
    width: 60px;
    height: 60px;
  }

  .GetAPrescription-feature i {
    font-size: 1.5em;
  }

  .GetAPrescription-feature p {
    font-size: 1.1em;
    text-align: center;
  }

  .RexVetsShop-content {
    flex-direction: column;
    align-items: center;
  }

  .RexVetsShop-text {
    max-width: 100%;
    text-align: center;
  }

  .RexVetsShop-title {
    font-size: 2em;
  }

  .RexVetsShop-description {
    font-size: 1.2em;
  }

  .RexVetsShop-button {
    padding: 15px;
    font-size: 1.1em;
  }

  .RexVetsShop-image-container {
    margin-left: 0;
    margin-top: 20px;
  }

  .RexVetsShop-image {
    width: 300px;
    height: 300px;
  }

  .RexVetsPhar-info-content {
    flex-direction: column;
  }

  .RexVetsPhar-info-image {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }

  .RexVetsPhar-info-text {
    max-width: 100%;
  }

  .RexVetsPhar-info-title {
    font-size: 2.5em;
  }

  .RexVetsPhar-info-description {
    font-size: 1.2em;
  }

  .vet-info h2 {
      font-size: 1.2em;
      text-align: center;
  }

  .vet-info p {
      font-size: 14px;
      text-align: center;
  }

  .vet-areas-reviews {
      margin-top: 20px;
  }


  .vet-interest-list {
      grid-template-columns: repeat(2, 1fr);
  }

  .vet-reviews-list {
      grid-template-columns: 1fr;
      margin-right: auto;
  }

  .vet-show-all-button {
      width: 100%;
      text-align: center;
  }

  .appointments1-container {
      padding: 10px;
  }

  .appointments1-header h2 {
      font-size: 1.2em;
  }

  .appointments1-big-icon {
      font-size: 6em;
  }

  .appointments1-book-button {
      width: 100%;
      font-size: 0.9em;
  }
}





.rexVetsbav-container {
  font-family: Arial, sans-serif;
  color: #FFFFFF;
  background-color: #002366;
  padding: 100px 200px 100px 200px; /* Añadido más margen en los costados */
  text-align: center;
}

.rexVetsbav-header {
  margin-bottom: 50px;
}

.rexVetsbav-header h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.rexVetsbav-header p {
  font-size: 1.2rem;
  color: #58f69f;
}

.rexVetsbav-content,
.rexVetsbav-schedule,
.rexVetsbav-records {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.rexVetsbav-communication,
.rexVetsbav-schedule-info,
.rexVetsbav-records-info {
  flex: 1 1 40%;
  text-align: left;
  padding: 20px;
}

.rexVetsbav-communication h2,
.rexVetsbav-schedule-info h2,
.rexVetsbav-records-info h2 {
  color: #58f69f;
  font-size: 2rem;
  margin-bottom: 20px;
}

.rexVetsbav-communication ul,
.rexVetsbav-schedule-info ul,
.rexVetsbav-records-info ul {
  list-style-type: none;
  padding: 0;
}

.rexVetsbav-communication li,
.rexVetsbav-schedule-info li,
.rexVetsbav-records-info li {
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #FFFFFF;
  line-height: 1.5;
}

.rexVetsbav-check {
  color: #58f69f; /* Color del check */
  margin-right: 10px;
}

.rexVetsbav-image-section {
  flex: 1 1 50%;
  text-align: center;
  position: relative;
}

.rexVetsbav-image {
  max-width: 100%;
  border-radius: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .rexVetsbav-content,
  .rexVetsbav-schedule,
  .rexVetsbav-records {
    flex-direction: column;
    margin: 0;
  }

  .rexVetsbav-communication,
  .rexVetsbav-schedule-info,
  .rexVetsbav-records-info,
  .rexVetsbav-image-section {
    flex: 1 1 100%;
    margin-bottom: 20px;
    padding: 0;
    width: 100%; /* Asegura que los elementos ocupen todo el ancho disponible */
  }

  .rexVetsbav-image {
    width: 100%; /* Imagen ajustada al 100% del contenedor */
    max-width: 100%;
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .rexVetsbav-header {
    padding: 0 10px; /* Añade padding para asegurar que el texto no toque los bordes de la pantalla */
  }

  .rexVetsbav-header h1 {
    font-size: 1.8rem;
  }

  .rexVetsbav-header p {
    font-size: 1rem;
  }

  .rexVetsbav-communication h2,
  .rexVetsbav-schedule-info h2,
  .rexVetsbav-records-info h2 {
    font-size: 1.5rem;
  }

  .rexVetsbav-communication li,
  .rexVetsbav-schedule-info li,
  .rexVetsbav-records-info li {
    font-size: 1rem;
  }
}
.rexVetsbav-benefits-container {
  font-family: Arial, sans-serif;
  color: #002366;
  padding: 40px 20px;
  text-align: center;
  padding-bottom: 70px;}

.rexVetsbav-benefits-container h2 {
  font-size: 2rem;
  color: #2b9159;
  margin-bottom: 30px;
}

.rexVetsbav-benefits-table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 10px;
}

.rexVetsbav-benefits-table {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  position: relative;
  border-spacing: 0;
  background-color: white;
}

.rexVetsbav-benefits-table th,
.rexVetsbav-benefits-table td {
  padding: 15px 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

.rexVetsbav-benefits-table th {
  background-color: white; /* Fondo limpio */
  font-weight: bold;
  position: relative;
}

.rexVetsbav-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.rexVetsbav-logo {
  width: 50px;
  height: 50px;
  padding: 5px;
  z-index: 2;
}

.rexVetsbav-benefits-table td {
  background-color: white; /* Fondo limpio */
}

.rexVetsbav-benefits-table td:nth-child(2) {
  color: #2b9159;
  font-weight: bold;/* Añadido el borde inferior */
  padding: 20px;
  position: relative;
  border-radius: 0px; /* Eliminar bordes redondeados individuales */
}
.TestBorder {
  border: 5px solid;
}

.rexVetsbav-benefits-table td:first-child:nth-child(2) {
  border-top-left-radius: 15px; /* Añadir esquinas redondeadas superiores */
  border-top-right-radius: 15px;
}

.rexVetsbav-benefits-table td:last-child:nth-child(2) {
  border-bottom-left-radius: 15px; /* Añadir esquinas redondeadas inferiores */
  border-bottom-right-radius: 15px;
}

.rexVetsbav-benefits-table td:nth-child(3) {
  color: #002366;
}

.rexVetsbav-description {
  display: block;
  color: #555;
  font-size: 1rem;
  margin-top: 5px;
  text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
  .rexVetsbav-benefits-table th,
  .rexVetsbav-benefits-table td {
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .rexVetsbav-benefits-container h2 {
    font-size: 1.5rem;
  }

  .rexVetsbav-logo {
    width: 40px;
    height: 40px;
  }

  .rexVetsbav-description {
    font-size: 0.8rem;
  }
}
.text-left {
  text-align: left;
}
.titleBenefitsTech {
  font-size: 40px!important;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 700;
}
.cta-container {
  background-color: #00021d;
  color: white;
  text-align: center;
  padding: 40px 20px;
  padding-bottom: 70px;
}

.cta-content {
  margin-bottom: 30px;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.cta-content p {
  font-size: 2rem;
  margin-bottom: 50px;
  
}

.cta-button {
  background-color: #2b9159;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 40px;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 50px;
  padding: 20px;
}

.cta-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-stat {
  background-color: white;
  color: #00021d;
  padding: 20px;
  border-radius: 15px;
  width: 350px;
  text-align: center;
}

.cta-stat h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.cta-stat p {
  font-size: 1rem;
  text-align: left;
}

.cta-stat strong {
  font-size: 4rem;
  display: block;
  text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .cta-stats {
    flex-direction: column;
    gap: 10px;
  }

  .cta-stat {
    max-width: 100%;
  }
}

.coughing-container {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 10%;
  padding-left: 10%;
  margin-bottom: 40px; /* Añadir espacio entre contenedores */
  background-color: #ffffff; /* Fondo suave */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para mayor profundidad */
}

.coughing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.coughing-text h2 {
  font-size: 36px;
  color: #123456;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0; /* Línea separadora debajo del título */
  padding-bottom: 10px;
}

.coughing-text p {
  font-size: 14px;
  color: #333333;
  margin-bottom: 20px;
  line-height: 1.8; /* Incrementar el interlineado para mejorar la legibilidad */
}

.vet-button {
  background-color: #34a853;
  color: white;
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 25px; /* Botón más estilizado y redondeado */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vet-button:hover {
  background-color: #2c8b43; /* Cambio de color en hover */
}
.coughing-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Asegura que el contenedor de la imagen tome todo el alto disponible */
}
.coughing-image img {
  width: 400px;
  height: 400px;
  border-radius: 50%; /* Bordes redondeados en la imagen */
  margin-top: 20px;
}

.considerations h3 {
  font-size: 30px;
  color: #345678;
  margin-bottom: 20px;
  border-bottom: 2px solid #dcdcdc; /* Línea separadora debajo del subtítulo */
  padding-bottom: 10px;
}

.considerations p,
.considerations ol {
  font-size: 14px;
  color: #555555;
  margin-bottom: 15px;
  line-height: 1.7;
}

.considerations ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.considerations li {
  margin-bottom: 10px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .coughing-content {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px; /* Más espacio lateral en pantallas grandes */
  }

  .coughing-text,
  .coughing-image {
    flex: 1;
    margin: 0 20px;
  }

  .coughing-image img {
    margin-top: 0;
  }
}


.PaymentSectionPublic {
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, .1);
  margin-top: 20px;
}

.navFindAVet {
  z-index: 1000; 
}

.content-area {
  margin-top: 80px; 
}

.loading-overlay {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #ffffff;
  color: #002366;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.vet-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 50px;
}

.vet-selection-content {
  text-align: left;
  margin-left: 0;
  align-self: flex-start; /* Si estás utilizando flexbox */
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1500px;
}

.vet-selection-title {
  font-size: 1.5rem;
}

.vet-selection-subtitle {
  font-size: 1rem;
  color: #555;
}

.vet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(395px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .vet-grid {
    grid-template-columns: 1fr;
  }
}

.other-services-tab {
  padding-top: 41px!important;
}

.vetAccountMenuHolder{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  gap: 20px;
}

@media (max-width: 1000px) {
  .vetAccountMenuHolder{
    justify-content: center;
  }
}
.ql-editor a {
  color: blue;
  text-decoration: underline;
}

.ql-editor a:hover {
  color: black;
}

.inter-font {
  font-family: Inter, sans-serif!important;
}
/* FAQ STYLES*/


.faqAccordion {
  width: 100%;
  max-width: 1000px;
  padding-top: 100px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  padding-bottom: 10px;
}

.accordionItem {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-family: Inter, sans-serif;
}

.accordionTitle h3 {
  margin: 0;
  font-size: 20px!important;
  font-weight: 400;
  font-family: Inter, sans-serif;

}
.faqAccordion h2 {
  color: #002366;
  font-size: 36px;
  font-family: Inter, sans-serif;
}

.accordionTitle span {
  font-size: 24px;
  transition: transform 0.2s ease;
  margin-right: 18px;
}

.accordionTitle:hover {
  color: #007BFF;
}

.accordionContent {
  padding: 10px 0;
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.accordionContent p {
  margin: 0 0 10px;
}

.accordionItem:last-child {
  border-bottom: none;
}

/* UI chats*/

.meetingButton {
  background: linear-gradient(135deg, #003366, #001f4d);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-weight: 400!important;
  transition: background 0.3s ease;
}

.meetingButton:hover {
  background: linear-gradient(135deg, #00224e, #001437);
}

/* Benefits cards */
.newDonationsection {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #FFFFF0;
}

.cardsBenefitsdonate {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;

}

.card {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 380px;
  padding: 20px;
  text-align: left; /* Alineación a la izquierda para un mejor estilo de texto */
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  position: relative;
}

.card.recommended {
  border: 2px solid #001337; /* Borde azul */
}

.card.recommended::before {
  content: "Recommended";
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f9a825; /* Color amarillo */
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
}

.benefits ul {
  list-style: none; /* Eliminamos el estilo de lista predeterminado */
  padding: 0;
}

.benefits li {
  margin-bottom: 10px; /* Espaciado entre los ítems de la lista */
}

.check-icon {
  color: green;
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

.card button {
  background: linear-gradient(45deg, #001337, #007bff); /* Degradado en el botón */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;
  width: 100%; /* Botón ocupando todo el ancho */
}

.card button:hover {
  background: linear-gradient(45deg, #001337, #0056b3);
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .card {
    width: 100%;
    height: auto;
  }

  .cardsBenefitsdonate {
    gap: 10px;
  }
}

/* Make a difference */
.support-info-wrapper {
  display: flex;
  align-items: center;
  max-width: 1200px; 
  margin: 0px auto; 
  gap: 40px; 
  font-family: 'Arial', sans-serif;
}

.support-info-image {
  flex: 1;
}

.support-info-image img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Bordes redondeados para la imagen */
}

.support-info-container {
  flex: 2;
}

.support-info-container h2 {
  color: white; /* Color del título */
  font-size: 24px;
  margin-bottom: 20px;
}

.support-info-container p {
  font-size: 19px;
  color: white;
  margin-bottom: 20px;
  line-height: 1.6; /* Espaciado entre líneas */
}

.support-info-container ul {
  list-style-type: none; /* Eliminamos las viñetas de la lista */
  padding: 0;
}

.support-info-container li {
  background-color: rgb(255, 255, 255); /* Color de fondo para los elementos de la lista */
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 0 15px 15px 0;
  border-left: 5px solid rgb(43, 145, 89); /* Borde a la izquierda con el color azul */
  font-size: 14px;
  line-height: 1.5;
}

.support-info-container strong {
  color: #001337; /* Color fuerte para las cantidades */
}

/* Estilos responsivos para pantallas más pequeñas */
@media (max-width: 768px) {
  .support-info-wrapper {
    flex-direction: column; /* La imagen se apila encima del contenido */
    padding: 15px;
  }

  .support-info-image {
    margin-bottom: 20px; /* Añadimos espacio entre la imagen y el contenido */
  }

  .support-info-container h2 {
    font-size: 20px; /* Reducción del tamaño del título en pantallas pequeñas */
  }

  .support-info-container p, .support-info-container li {
    font-size: 14px; 
  }
}

/* Testimonials */

.carrouselDonations {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto; 
  padding: 20px; 
  border-radius: 15px;
}

.testimonial-card {
  text-align: center;
  margin-bottom: 20px;
}

.testimonial-img {
  width: 150px;
  height: 150px;
  border-radius: 50%; 
  object-fit: cover; 
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(56, 56, 56, 0.1); 
}

.testimonial-quote {
  font-size: 18px;
  color: #464646;
  margin-bottom: 10px;
  margin-top: 10px;
  font-style: italic;
}

.testimonial-name {
  font-size: 16px;
  font-weight: bold;
  color: #001337; 
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  width: 100px; 
}

.carousel-button {
  width: 40px; 
  height: 40px;
  background-color: #001337;
  color: white;
  border: none;
  border-radius: 50%; 
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;
}

.carousel-button:hover {
  background-color: #0056b3; 
}

/* Responsivo */
@media (max-width: 768px) {
  .carrouselDonations {
    max-width: 100%; 
  }

  .testimonial-quote {
    font-size: 16px;
  }

  .testimonial-name {
    font-size: 14px;
  }

  .carousel-controls {
    width: 80px;
  }

  .carousel-button {
    padding: 8px;
    font-size: 16px;
  }
}

/* Our Mission Page */
.RexVetsOur-info-container {
  background-color: #FFFFF0;
  padding: 60px 30px;
  display: flex;
  justify-content: center;
}
.OurMission-description {
  font-size: 1.2em;
  margin-bottom: 45px;
  font-weight: 700;
  margin-top: 10px;
  
}

.Info-info-wrapper {
  display: flex;
  align-items: center;
  max-width: 1400px; 
  margin: 0px auto; 
  gap: 40px; 
  font-family: 'Arial', sans-serif;
  padding-top: 40px;
  padding-bottom: 40px;
}


.RexVetsOur-info-content {
  display: flex;
  max-width: 1500px;
  justify-content: center;
  gap:80px
}

.RexVetsOur-info-image-container {
  margin-right: 30px;
}

.RexVetsOur-info-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.RexVetsOur-info-text {
  max-width: 50%;
}

.RexVetsOur-info-title {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.RexVetsOur-info-list {
  list-style: none;
  padding: 0;
}

.RexVetsOur-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.RexVetsOur-info-icon {
  background-color: #001a2c;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.RexVetsOur-info-description {
  font-size: 18px;
  color: #333;
}
.Our-feature-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  background-color: rgb(0, 34, 97) ;
  border-radius: 50%;
}
.Our-background {
  background-image: url('../../1_MediaAssets/MainPages/ourmissionbg.jpg');
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  color: white;
  text-align: left;
  margin-top: 70px;
  min-height: 650px;
}
.Our-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

/* Non profit section */

.nonprofit-section{
  background-image: url("../../1_MediaAssets/MainPages/nonprofitimage.png");
  background-repeat:no-repeat;
  background-position: top right;
}

.page-nonprofit {
  background-color: rgb(0, 34, 97);
  padding-top: 20px;
}

.nonprofit-background {
  background-image: url('../../1_MediaAssets/MainPages/bgnonprofit.png');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: left;
  margin-top: 80px;
  min-height: 480px;
  padding-bottom: 300px;
}
/* Financial page */
.financial-background {
  background-image: url('../../1_MediaAssets/MainPages/ourmissionbg.png');
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  color: white;
  text-align: left;
  margin-top: 70px;
  max-height: 350px;
}
.financial-content {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; 
}
.financial-container {
  background: #001b4f;
  color: white;
  text-align: center;
  padding-top: 60px;
}
.cta-button {
  font-weight: 700;
}
.cta-button:hover {
  background-color: #002366;
  color: white;
}

.financial-content1 {
  max-width: 740px;
  margin-left: 250px;
}
/* Support Us page */

.supportus-background {
  background-image: url('../../1_MediaAssets/MainPages/bgsupport.png');
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  color: white;
  text-align: left;
  margin-top: 70px;
  min-height: 650px;
}

.supportus-options {
  display: flex;
  justify-content: space-around;
  align-items: center;  
  margin-left: 200px;
  margin-right: 200px;
  padding: 40px 20px;
}

.optionsbg {
  background-color: #fafaff;
}

.supportus-option-height {
  min-height: 210px;
}

.supportus-option {
  text-align: center;
  max-width: 400px;
  margin: 0 20px;
}

.supportus-icon {
  font-size: 3rem;
  color: #2b9159;
  margin-bottom: 10px;
}

.supportus-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.supportus-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.supportus-button {
  background-color: #2b9159;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}

.supportus-button:hover {
  background-color: #09002c;
}

@media (max-width: 1200px) {
  .help-support-title, .help-support-text {
    margin-left: 100px;
  }

  .supportus-options {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (max-width: 992px) {
  .help-support-title {
    font-size: 3.5rem;
    margin-left: 50px;
  }

  .help-support-text {
    font-size: 1rem;
    margin-left: 50px;
  }

  .supportus-options {
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
  }

  .supportus-option {
    max-width: 100%;
    margin: 20px 0;
    padding-bottom: 30px;
    border-bottom: 2px solid #2b9159;
  }

  .connect-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .help-support-component {
    flex-direction: column;
    height: auto;
  }

  .help-support-content {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .help-support-title, .help-support-text {
    margin: 0;
    max-width: 100%;
  }

  .supportus-options {
    margin: 20px 0;
    padding: 20px;
  }

  .connect-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 576px) {
  .help-support-title {
    font-size: 2.5rem;
  }

  .help-support-text {
    font-size: 0.9rem;
  }

  .supportus-button, .connect-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .Info-info-wrapper {
    flex-direction: column;
    padding: 20px;
  }

  .support-info-image img {
    width: 100%!important;
    height: auto;
  }

  .support-info-container {
    margin-top: 20px;
    text-align: center;
  }

  .fontsizee {
    font-size: 1.5em;
  }

  .RexVetsOur-info-container {
    padding: 20px;
  }
.dashboardVetsResponsive {
  width: 95%!important;
}
.dashboard-container {
  width: 95%!important;
}
.vet-selection-container {
  margin: 0;
}
.petsResponsive {
  width: 95%!important;
}
  .RexVetsOur-info-content {
    flex-direction: column;
    width: 100%;
  }
.card-container-works {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.Pasos-talk-button {
  text-align: center;
}
.Pasos-image-container {
  min-width: auto!important;
  max-width: 100%;
}
  .RexVetsOur-info-text {
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .RexVetsOur-info-title {
    font-size: 2em;
  }

  .RexVetsOur-info-description {
    font-size: 1.1em;
  }

  .RexVetsOur-info-image-container {
    margin-top: 20px;
    text-align: center;
  }

  .RexVetsOur-info-image {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
  .veterinary-title {
    font-size: 2em;
    margin-left: 15px;
  }
  .financial-content1{
    margin-left: 20px;
  }
  .about-section {
    background-image: None;
  }
  .nonprofit-section {
    background-image: None;
  }
  .veterinary-description {
    font-size: 1.3em;
    margin-left: 15px;
  }

  .veterinary-chat-container {
    display: none;
  }
  .veterinary-subscribe-button {
    padding: 8px 16px;
    font-size: 1.3em;
    color: white;
    margin-left: 15px;
  }
}

@media (max-width: 499px) {
  .veterinary-title {
    font-size: 2em;
    margin-left: 15px;
  }
  .about-section {
    background-image: None;
  }
  .veterinary-description {
    font-size: 1.3em;
  }

  .veterinary-subscribe-button {
    padding: 8px 16px;
    font-size: 1.3em;
    color: white;
  }

  .veterinary-content {
    padding: 15px;
  }


}


.dashboard-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  width: 75%;
  margin-top: 50px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #dedcd7;
}

.dashboard-title {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.dashboard-icon {
  font-size: 30px;
  margin: 0px 10px 0px 5px;
  color: #0849c1;
}

.welcome-text {
  font-family: 'Inter, sans-serif';
  color: #4240DF;
  font-weight: 900;
  margin-bottom: 20px;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.dashboard-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  width: 49%;
  text-align: left;
  position: relative;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-image {
  border-radius: 50%;
  width: 96px;
  height: 96px;
  margin-right: 15px;
}

.card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-video, .icon-comments {
  color: white;
  font-size: 16px;
  margin-right: 5px;
  max-width: 15px;
  max-height: 15px;
  border-radius: 50%;
  background-color: #4240DF;
  padding: 7px;
}

.status-available, .status-online {
  font-size: 14px;
  color: #6c757d;
  background-color: #eaffea;
  border-radius: 15px;
  border: 1px solid #00b114;
  padding: 3px 9px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.status-icon {
  color: #28a745;
  font-size: 7px;
  margin-right: 5px;
}

.status-text {
  color: #28a745;
  margin-right: 5px;
  font-weight: 400;
}

.card-title {
  font-family: 'Inter, sans-serif';
  font-size: 18px;
  margin: 5px 0;
  color: #373737;
}

.card-body {
  margin-left: 30px;
}

.card-description {
  font-size: 16px;
  color: #6B7280;
  margin-bottom: 15px;
  font-family: 'Inter, sans-serif';
}

.book-appointment-btn {
  background: linear-gradient(to right, #0015a7, #5667df);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.common-questions-title {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: 'Inter, sans-serif';
  color: #4240df;
}

.common-questions-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 15px;
}

.icon-paw {
  margin-right: 8px;
  color: #4240df;
}

.common-questions-list li {
  margin-bottom: 8px;
  font-family: 'Inter, sans-serif';
  color: #6B7280;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .dashboard-content {
      flex-direction: column;
  }

  .dashboard-card {
      width: 100%;
      margin-bottom: 20px;
  }
}

.appointment-details-container {
  background-color: #FFE699;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  gap: 10px;
  padding: 50px 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.appointment-details-card {
  display: flex;
  background: white;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.appointment-title {
  margin: 20px 0;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #333;
}

.appointment-info {
  margin: 5px 0px 0px 30px;
  font-size: 15px;
  color: #555;
}

.pet-concerns-list {
  list-style-type: none;
  padding: 0;
}

.pet-concern-item {
  font-size: 13px;
  color: #555;
}

.confirm-button,
.return-button {
  width: 100%;
  border-radius: 5px;
  background: #002366;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s;
}

.confirm-button:hover,
.return-button:hover {
  background: #00509E; /* Lighter shade on hover */
}

.return-button {
  margin-right: 10px;
} 


.makecolHomeHero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}


@media screen and (max-width: 1000px) { 
  .makecolHomeHero {
    flex-direction: column;
    align-items: flex-start;
  }
}



















  .vet-profile {
  display: flex;
  margin-bottom: 20px;
  margin-top: 25px;
  }
  .vet-profile-img {
  border-radius: 50%;
  width: 13em;
  height: 13em;
  margin-right: 1.5em;
  }


  .dashboardmain2 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-bottom: 50px;
  }
  
  .scroll-container {
    height: auto;
  }
  
  .vet-section {
    display: flex;
    padding: 1rem;
    margin-left: 9%;
    margin-right: 15%;
  }
  
  .vet-content {
    flex: 1;
    margin-right: 30%; /* This margin leaves space for the appointment box */
    overflow-y: visible;
    margin-top: 70px;
  }
  
  .vet-appointment {
    position: fixed;
    right: 10%; /* Adjust this based on your design */
    top: 80px;
    width: 100%;
    max-width: 400px; /* Max width set to 300px */
    max-height: 80vh;
    overflow-y: auto;
    /* padding: 10px; */
    margin-top: 20px;
    box-sizing: border-box;
  }
  
  .vet-appointment .price {
    font-size: 13px; /* Reduced font size for appointment slots */
  }
  
  @media screen and (max-width: 768px) {
    .vet-section {
      flex-direction: column;
      margin-left: 0%;
      margin-right: 0%;
    }
  
    .vet-content {
      margin-right: 0;
      margin-bottom: 20px;
      margin-top: 20px;
      max-height: none;
    }
  
    .vet-appointment {
      position: static;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 20px;
      max-height: none;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .vet-section {
      flex-direction: row;
      padding: 15px;
    }
  
    .vet-content {
      margin-right: 0;
      margin-bottom: 20px;
      max-height: auto;
      margin-top: 30px;
    }
  
    .vet-appointment {
      position: static;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }
  
@media screen and (max-width: 768px) { 
  .vet-section {
  flex-direction: column;
  margin-left: 0%;
  margin-right: 0%;
  }
  .vet-content {
  margin-right: 0;
  margin-bottom: 20px;
  max-height: auto;
  margin-top: 20px;
  }
  .vet-profile {
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  }
  .vet-profile-img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  }
  .vet-areas-of-interest,
  .vet-licenses,
  .vet-reviews {
  margin-bottom: 40px;
  }
  .vet-areas-of-interest,
  .vet-licenses,
  .vet-reviews {
  margin-bottom: 20px;
  }
  
  .vet-appointment {
  position: static;
  width: 100%;
  /* padding: 20px; */
  margin-bottom: 20px;
  margin-top: 20px;
  border-left: none;
  right: 0;
  }

}

.Upcoming {
  display: inline-block;
  background-color: #E0F7FA; /* Celeste claro */
  color: #0277BD; /* Celeste más oscuro */
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  border: 0.5px solid #B2EBF2; /* Celeste ligeramente más oscuro */
}

.Incomplete {
  display: inline-block;
  background-color: #FFE5E5; /* Rojo claro */
  color: #B71C1C; /* Rojo más fuerte */
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  border: 0.5px solid #FFCDD2; /* Rojo ligeramente más fuerte */
}

.Complete {
  display: inline-block;
  background-color: #F0FDF4;
  color: #15803D;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  border: 0.5px solid rgb(187 247 208);
}
/* @media (min-width: 769px) and (max-width: 1024px) {
.vet-section {
    flex-direction: row;
    padding: 15px;
}

.vet-content {
    margin-right: 0;
    margin-bottom: 20px;
    max-height: auto;
    margin-top: 30px;
}

.vet-appointment {
    position: static;
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-left: none;
    right: 0;
}

.vet-profile {
    flex-direction: row;
}

.vet-profile-img {
    width: 160px;
    height: 160px;
    margin-bottom: 10px;
}

.vet-info h2 {
    font-size: 1.4em;
    text-align: left;
}

.vet-info p {
    font-size: 15px;
    text-align: left;
}

.vet-areas-reviews {
    margin-top: 30px;
}

.vet-areas-of-interest,
.vet-licenses,
.vet-reviews {
    margin-bottom: 30px;
}

.vet-interest-list {
    grid-template-columns: repeat(2, 1fr);
}

.vet-reviews-list {
    grid-template-columns: repeat(2, 1fr);
}

.vet-show-all-button {
    width: 100%;
    text-align: center;
}

.appointments1-container {
    padding: 15px;
}

.appointments1-header h2 {
    font-size: 1.4em;
}

.appointments1-big-icon {
    font-size: 7em;
}

.appointments1-book-button {
    width: 100%;
    font-size: 1em;
}
} */