.Navbar2 {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background: #002366;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 250px;
  z-index: 999 !important;
}

.Navbar2 ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0px;
  width: 100%;
}

.Navbar2 ul li {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.checkbtn2 {
  font-size: 30px;
  color: white;
  float: right;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

.nav-linky {
  text-decoration: none;
  height: 40px;
  font-size: 14px;
  color: white;
  font-weight: 900;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  text-align: center;
}

.nav-linky:hover {
  background-color: #0849c1;
}

.nav-linky.active {
  color: black; /* Change to the desired color */
  background-color: #82adff;
}
.mobileLogOut {
  display: none;
}
@media (max-width: 1000px) {
  .mobileLogOut {
    margin: 0 auto;
    display: block;
  }
  .Navbar2 {
    justify-content: flex-start ;    
    height: 50px ;
    width: 100vw ;
    top: 0 ;
    z-index: 999 ;
  }
  .Burger {
    margin-right: 20px ;
  }
  .checkbtn2 {
    display: flex ;
    align-items: center ;
    justify-content: flex-end ;
    width: 100vw ;
    height: 50px ;
    margin: 0;
    padding: 0;
    position: absolute ;
    z-index: 1000 ; /* Ensure it is above other elements */
    background: #002366;
  }

  .Navbar2 .ulnav2 {
    position: fixed ;
    display: flex ;
    justify-content: flex-start ;
    align-items: flex-start ;
    margin: 0;
    padding: 20px 0px 0px 0px ;
    width: 100vw ;
    height: calc(100vh - 50px) ;
    background: #002366;
    top: 50px ;
    right: -100% ;
    text-align: left ;
    transition: all 0.5s;
    flex-direction: column ;
    z-index: 200 ;
  }

  .Navbar2 .ulnav2 li {
    width: 100% ;
    height: auto ;
    margin: 10px 0 ;
  }

  .nav-linky {
    width: 100% ;
    padding: 10px 20px ;
  }
  #check:checked ~ .ulnav2 {
    right: 0 ;
  }
}