.Navbar2{
    position: absolute;
    left: 0px;
    background: #002366;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 250px;
  }
  .Navbar2 ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 0px;
    width: 100%;
  }
  
  .Navbar2 ul li{
    display: inline-block;
    width: 100%;
    text-align: center;
  }


.checkbtn2{
    font-size: 30px;
    color: white;
    float: right;
    cursor: pointer;
    display: none;
  }
  #check{
    display: none;
  }

  .nav-linky {
    text-decoration: none;
    height: 40px;
    font-size: 14px;
    color: white;
    font-weight: 900;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px,
  }
  
  .nav-linky:hover {
    background-color: #225082;
    color: white;
  }
  
  .nav-linky.active {
    color: rgb(255, 255, 255); /* Change to the desired color */
    background-color: #225082;
  }

  @media (max-width: 1000px) {
    .Navbar2 {
      justify-content: flex-start;    
      height: 50px;
      width: 100vw;
      top: 0;
    }
    .Burger {
      margin-right: 20px;
    }
    .checkbtn2 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100vw;
      height: 50px;
      margin: 0;
      padding: 0;
      position: absolute;
      z-index: 1;
      background: #002366;    
    }
    
    .Navbar2 ul {
      position: fixed;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; /* Alineación al inicio en lugar de al centro */
      margin: 0;
      padding: 20px 0px 0px 0px;
      width: 55vw; /* Ajuste de ancho */
      height: calc(100vh - 50px);
      background: #fff;
      top: 50px;
      right: -100%;
      text-align: left; /* Alineación del texto a la izquierda */
      transition: all 0.5s;
      flex-direction: column;
    }
    
    .Navbar2 ul li {
      height: auto; /* Altura automática según el contenido */
      margin-bottom: 15px; /* Ajuste del margen inferior */
    }
    
    #check:checked ~ ul {
      right: 0;
    }
  
    .nav-linky {
      justify-content: flex-start; /* Alineación a la izquierda */
      padding-left: 20px; /* Espacio del borde izquierdo */
      padding-right: 10px; /* Ajuste del padding derecho */
      font-size: 14px; /* Tamaño de fuente */
    }
  }