body {
	background-color: #ffffff;
}

// all animals have this default setup for body, face and features elements
// saves setting it up each time
.body {
	width: 90px;
	height: 100px;

	&, &:before, &:after {
	position: absolute;
	}
	&:before, &:after {
	content: '';
	}
}
	.face,
	.features {
		&, &:before, &:after {
			position: absolute;
		}
		&:before, &:after {
			content: '';
		}
	}

// Specific styles

$defaultBodyColor: #FFE699;

//Slanted Body
.bodySlant {
	background-color: $defaultBodyColor;
	border-radius: 4px;
	transform-origin: 50% 100%;
	transform: perspective(300px) rotateX(20deg);
}

.earsBasic {
	// A basic style for Ears
	&:before,
	&:after {
		width: 0;
		height: 0;

		top: -25px;

		border-top: 28px solid transparent;
		border-bottom: 20px solid transparent;
	}

	&:before {
		border-left: 20px solid $defaultBodyColor;
		left: 0;
	}
	&:after {
		border-right: 20px solid $defaultBodyColor;
		right: 0;
	}
}


// Styling for the Cat
// uses bodySlant and earsBasic as a base
.cat {
	bottom: 0;
	right: 50px;

	.face {
		// Eyes
		top: 22px;
		left: 20px;
		width: 6px;
		height: 6px;
		background-color: #000;

		border-radius: 50%;
		box-shadow: 44px 0 #000;
		animation: blink 8s infinite linear;

		// Mouth
		&:before,
		&:after {
			top: 14px;
			left: 6px;
			z-index: 5;
			width: 16px;
			height: 20px;
			border-radius: 50% / 40%;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			border-left: 2px solid transparent;
			border-top: 2px solid transparent;

			box-shadow: 0px 0px #000;
		}

		//Rotated to make other side of mouth
		&:after {
			left: 24px;
			transform: rotateY(180deg);
		}
	}
	.features {
		//Whiskers
		top: 44px;
		left: 11px;
		width: 20px;
		height: 1px;

		background-color: #000;
		box-shadow: 0 5px #000,
		48px 0 #000,
		48px 5px #000;

		//nose
		&:after {
			left: 30px;
			top: -6px;
			width: 6px;
			height: 6px;
			background-color: #000;

			border-radius: 50%;

			box-shadow: 2px 0 #000,
				1px 1px #000;

			animation: yawn 8s infinite linear;
		}
		&:before {
			//Tail
			top: 10px;
			left: 74px;
			width: 40px;
			height: 40px;

			border-radius: 50%;
			border: 5px solid $defaultBodyColor;
			border-left: 0;
			border-top: 0;
		}
	}
    // animation: upAndDownCat 10s infinite;
}
// Define keyframes for hiding the cat
// @keyframes upAndDownCat {
//   0%, 100% {
//     bottom: 0px; // Initial position
//   }
//   50% {
//     bottom: -120px; // Move off-screen
//   }
// }
@keyframes blink {
	32% { height: 6px; }
	38% { height: 1px; }
	42% { height: 1px; }
	48% { height: 6px; }
}
@keyframes yawn {
	32% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px transparent,
				2px 16px transparent,
				1px 18px transparent;
	}
	35% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px #650000,
				2px 16px #650000,
				1px 18px transparent;
	}
	38% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px #650000,
				2px 16px #650000,
				1px 18px #650000;
	}
	42% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px #650000,
				2px 16px #650000,
				1px 18px #650000;
	}
	45% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px #650000,
				2px 16px #650000,
				1px 18px transparent;
	}
	48% {
		box-shadow: 2px 0 #000,
				1px 1px #000,
				0px 16px transparent,
				2px 16px transparent,
				1px 18px transparent;
	}
}




// dog
$dogBodyColor : #a3701d;

.dog {
	right: -50px;
	bottom: 200px;
	background-color: $dogBodyColor;
    
	transform: rotate(270deg); // Rotate the dog by 90 degrees

	// Ears
	&:before {
		top: -28px;
		left: 0;
		width: 0;
		height: 0;
		border-left: 30px solid $dogBodyColor;
		border-radius: 30% 0 0 / 40% 40% 0 0;

		border-top: 28px solid transparent;
		border-bottom: 20px solid transparent;
	}
	&:after {
		right: 0;
		z-index: -5;
		border-right: 30px solid #a3701d;
		border-radius: 0 15px 0 0;
	}

	.face {
		//eyes and nose
		top: 22%;
		left: 22%;
		width: 6px;
		height: 6px;

		background-color: #000;
		border-radius: 50%;

		box-shadow: 44px 0 #000,
			19px 15px #000,
			21px 15px #000,
			23px 15px #000,
			25px 15px #000,

			22px 18px #000;

		// Mouth
		&:before,
		&:after {
			top: 14px;
			left: 6px;
			width: 16px;
			height: 20px;
			border-radius: 50% / 40%;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			border-left: 2px solid transparent;
			border-top: 2px solid transparent;

			box-shadow: 0px 0px #000;
		}
		&:after {
			left: 24px;
			transform: rotateY(180deg);
		}
	}

	.features {
		// Patch over right eye
		right: 6px;
		top: 5px;
		z-index: -1;
		width: 30px;
		height: 35px;
		background-color: #d6d6d6;

		border-radius: 50% 40% 40% 50% / 30% 30% 30% 30%;

		//tongue
		&:after {
			top: 51px;
			right: 34px;
			width: 10px;
			height: 10px;

			background-color: #df4f4f;
			border-radius: 0 0 50% 50%;

			animation: tongue 1s infinite ease-in;
		}
		//tail
		&:before {
			top: 35px;
			right: -35px;
			z-index: -1;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 5px solid #a3701d;
			border-left: 0;
			border-top: 0;

			box-shadow: 3px 0 #f1f1f1,
			6px 5px #a3701d,
			9px 5px #f1f1f1, 
			10px 12px #a3701d;

			animation: wag 5s infinite ease-in-out;
		}
	}
    animation: upAndDownDog 15s infinite;
}

@keyframes upAndDownDog {
    0%, 100% {
        right: -50px;
    }
    50% {
        right: -120px;
    }
  }

@keyframes tongue {
	0% { height: 10px; }
	50% { height: 13px; }
}
@keyframes wag {
	10% { top: 25px; right: -30px; transform: rotate(-20deg); }
	20% { top: 35px; right: -35px; transform: rotate(0); }
	30% { top: 25px; right: -30px; transform: rotate(-20deg); }
	40% { top: 35px; right: -35px; transform: rotate(0); }
	50% { top: 30px; right: -32px; transform: rotate(-10deg); }
	60% { top: 35px; right: -35px; transform: rotate(0); }
}

