	/*
  	Flaticon icon font: Flaticon
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticon.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}



[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-game:before { content: "\f100"; }
.flaticon-animals-15:before { content: "\f101"; }
.flaticon-cross:before { content: "\f102"; }
.flaticon-animal-13:before { content: "\f103"; }
.flaticon-shape:before { content: "\f104"; }
.flaticon-cat-toy:before { content: "\f105"; }
.flaticon-pet-lotion:before { content: "\f102"; }
.flaticon-cat-in-black-silhouette:before { content: "\f107"; }
.flaticon-cat-2:before { content: "\f108"; }
.flaticon-ashera-cat:before { content: "\f109"; }
.flaticon-animals-14:before { content: "\f10a"; }
.flaticon-animals-13:before { content: "\f10b"; }
.flaticon-animals-12:before { content: "\f10c"; }
.flaticon-silhouette:before { content: "\f10d"; }
.flaticon-animal-12:before { content: "\f10e"; }
.flaticon-black-cat:before { content: "\f10f"; }
.flaticon-animal-prints:before { content: "\f110"; }
.flaticon-garden:before { content: "\f111"; }
.flaticon-dog-20:before { content: "\f112"; }
.flaticon-dog-19:before { content: "\f113"; }
.flaticon-people-2:before { content: "\f114"; }
.flaticon-animals-11:before { content: "\f115"; }
.flaticon-people-1:before { content: "\f116"; }
.flaticon-dog-18:before { content: "\f117"; }
.flaticon-people:before { content: "\f118"; }
.flaticon-animals-10:before { content: "\f119"; }
.flaticon-dog-17:before { content: "\f11a"; }
.flaticon-man:before { content: "\f11b"; }
.flaticon-animal-11:before { content: "\f11c"; }
.flaticon-animal-10:before { content: "\f11d"; }
.flaticon-dog-16:before { content: "\f11e"; }
.flaticon-animals-9:before { content: "\f11f"; }
.flaticon-animal-9:before { content: "\f120"; }
.flaticon-dog-15:before { content: "\f121"; }
.flaticon-animals-8:before { content: "\f122"; }
.flaticon-dog-14:before { content: "\f123"; }
.flaticon-easter-bunny:before { content: "\f124"; }
.flaticon-grooming-1:before { content: "\f125"; }
.flaticon-pawprint-4:before { content: "\f126"; }
.flaticon-pawprint-3:before { content: "\f127"; }
.flaticon-cat-1:before { content: "\f128"; }
.flaticon-dog-with-belt-walking-with-a-man:before { content: "\f129"; }
.flaticon-dog-13:before { content: "\f12a"; }
.flaticon-grooming:before { content: "\f12b"; }
.flaticon-prize-badge-with-paw-print:before { content: "\f12c"; }
.flaticon-dog-12:before { content: "\f12d"; }
.flaticon-dog-11:before { content: "\f12e"; }
.flaticon-pawprint-2:before { content: "\f12f"; }
.flaticon-dog-seating:before { content: "\f130"; }
.flaticon-dog-10:before { content: "\f131"; }
.flaticon-plain-dog:before { content: "\f132"; }
.flaticon-jack-russell-terrier:before { content: "\f133"; }
.flaticon-dog-9:before { content: "\f134"; }
.flaticon-walking-with-dog:before { content: "\f135"; }
.flaticon-pet:before { content: "\f177"; }
.flaticon-face-of-staring-dog:before { content: "\f137"; }
.flaticon-dog-with-floppy-ears-1:before { content: "\f138"; }
.flaticon-face-of-a-dog-with-sleepy-eyes:before { content: "\f139"; }
.flaticon-dog-bone-1:before { content: "\f13a"; }
.flaticon-dog-with-big-and-pointy-ears:before { content: "\f13b"; }
.flaticon-dog-head:before { content: "\f13c"; }
.flaticon-border-collie-head:before { content: "\f13d"; }
.flaticon-bulldog-head:before { content: "\f13e"; }
.flaticon-basset-hound-dog-head:before { content: "\f13f"; }
.flaticon-dog-face:before { content: "\f140"; }
.flaticon-veterinarian-hospital:before { content: "\f182"; }
.flaticon-rat:before { content: "\f142"; }
.flaticon-cat:before { content: "\f143"; }
.flaticon-dog-8:before { content: "\f144"; }
.flaticon-animal-8:before { content: "\f145"; }
.flaticon-hamster-1:before { content: "\f146"; }
.flaticon-animals-7:before { content: "\f147"; }
.flaticon-dog-training-3:before { content: "\f148"; }
.flaticon-dog-training-2:before { content: "\f149"; }
.flaticon-walking-the-dog:before { content: "\f14a"; }
.flaticon-dog-training-1:before { content: "\f195"; }
.flaticon-dog-training:before { content: "\f14c"; }
.flaticon-dog-7:before { content: "\f14d"; }
.flaticon-dog-6:before { content: "\f14e"; }
.flaticon-dog-5:before { content: "\f14f"; }
.flaticon-animals-6:before { content: "\f150"; }
.flaticon-animal-7:before { content: "\f151"; }
.flaticon-siamese-cat:before { content: "\f152"; }
.flaticon-toyger-cat:before { content: "\f153"; }
.flaticon-pets:before { content: "\f154"; }
.flaticon-bulldog:before { content: "\f155"; }
.flaticon-poodle:before { content: "\f156"; }
.flaticon-border-collie:before { content: "\f157"; }
.flaticon-basset-houd:before { content: "\f158"; }
.flaticon-dachshund:before { content: "\f159"; }
.flaticon-kurzhaar:before { content: "\f15a"; }
.flaticon-bernese-mountain:before { content: "\f15b"; }
.flaticon-dog-puppy:before { content: "\f15c"; }
.flaticon-dog-kennel:before { content: "\f15d"; }
.flaticon-mouse:before { content: "\f15e"; }
.flaticon-turtle:before { content: "\f15f"; }
.flaticon-pet-carrier:before { content: "\f160"; }
.flaticon-pet-food:before { content: "\f161"; }
.flaticon-dog-bone:before { content: "\f162"; }
.flaticon-aquarium:before { content: "\f163"; }
.flaticon-bird:before { content: "\f164"; }
.flaticon-laying-cat:before { content: "\f165"; }
.flaticon-pet-friendly:before { content: "\f166"; }
.flaticon-animals-5:before { content: "\f167"; }
.flaticon-animals-4:before { content: "\f168"; }
.flaticon-pawprint-1:before { content: "\f169"; }
.flaticon-food:before { content: "\f16a"; }
.flaticon-animals-3:before { content: "\f16b"; }
.flaticon-dog-4:before { content: "\f16c"; }
.flaticon-animals-2:before { content: "\f16d"; }
.flaticon-animal-6:before { content: "\f16e"; }
.flaticon-veterinary:before { content: "\f16f"; }
.flaticon-syringe:before { content: "\f170"; }
.flaticon-medical:before { content: "\f171"; }
.flaticon-pet-shelter:before { content: "\f170"; }
.flaticon-brush:before { content: "\f173"; }
.flaticon-animal-5:before { content: "\f174"; }
.flaticon-animal-4:before { content: "\f175"; }
.flaticon-animals-1:before { content: "\f176"; }
.flaticon-vet-with-cat:before { content: "\f177"; }
.flaticon-guinea-pig-heag:before { content: "\f178"; }
.flaticon-animals:before { content: "\f179"; }
.flaticon-angry-bulldog-face:before { content: "\f17a"; }
.flaticon-dog-with-chubby-cheeks:before { content: "\f17b"; }
.flaticon-chewing-bone-for-dog:before { content: "\f17c"; }
.flaticon-dog-food-bowl:before { content: "\f17d"; }
.flaticon-doberman-dog-head:before { content: "\f17e"; }
.flaticon-dog-with-floppy-ears:before { content: "\f17f"; }
.flaticon-long-haired-dog-head:before { content: "\f180"; }
.flaticon-animal-paw-print:before { content: "\f181"; }
.flaticon-dog-with-first-aid-kit-bag:before { content: "\f182"; }
.flaticon-play:before { content: "\f183"; }
.flaticon-dog-and-pets-house:before { content: "\f184"; }
.flaticon-man-combing-a-dog:before { content: "\f185"; }
.flaticon-dog-in-front-of-a-man:before { content: "\f186"; }
.flaticon-scissors:before { content: "\f187"; }
.flaticon-dog-3:before { content: "\f188"; }
.flaticon-dog-2:before { content: "\f189"; }
.flaticon-pet-hotel-sign-with-a-dog-and-a-cat-under-a-roof-line:before { content: "\f18a"; }
.flaticon-circle:before { content: "\f18b"; }
.flaticon-dog-1:before { content: "\f18c"; }
.flaticon-pet-hotel-symbols-of-three-stars-a-semicircle-and-a-bone-black-shape:before { content: "\f18d"; }
.flaticon-pets-hotel-symbol-with-a-dog-and-a-cat-in-a-circle-with-one-star:before { content: "\f18e"; }
.flaticon-animal-3:before { content: "\f18f"; }
.flaticon-animal-2:before { content: "\f190"; }
.flaticon-sign:before { content: "\f191"; }
.flaticon-dog-pet-allowed-hotel-signal:before { content: "\f192"; }
.flaticon-zynga-logotype:before { content: "\f193"; }
.flaticon-dog:before { content: "\f194"; }
.flaticon-animal-1:before { content: "\f16f"; }
.flaticon-pawprint:before { content: "\f196"; }
.flaticon-sitting-rabbit:before { content: "\f197"; }
.flaticon-track:before { content: "\f198"; }
.flaticon-hamster:before { content: "\f199"; }
.flaticon-animal:before { content: "\f19a"; }