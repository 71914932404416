.menu-logo {
  display: table;
  float: left;
  align-items: center;
  padding: 0;
  margin-top: 15px;
  color: #2b9159;
  width: 200px;
  height: 40px;
  position: relative;
  z-index: 9;
}
.menu-logo a {
  display: table-cell;
  vertical-align: middle;
}
.menu-logo img {
  height: auto;
  width: 35%;
}
.menu-logo span {
  font-size: 25px;
  letter-spacing: 25px;
}
@media (max-width: 1024px) {
  .menu-logo {
    height: 85px;
  }
}
@media (max-width: 768px) {
  .menu-logo {
    width: 180px;
  }
}
@media (max-width: 480px) {
  .menu-logo {
    height: 75px;
    width: 150px;
  }
}

.header {
  position: relative;
  z-index: 9999;
}
.navBarColor{
  background-color: #002366!important;
}
.menu-bar {
  background: #002366;
  width: 100%;
  position: relative;
}
.container {
  position: relative;
}
.secondary-menu {
  float: right;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  padding-left: 20px;
}
.secondary-menu .search-btn {
  margin-right: 35px;
}
.secondary-menu .search-btn .btn-link {
  font-size: 25px;
  line-height: 25px;
  color: #565acf;
  cursor: pointer;
  border: 0;
  padding: 8px 0;
  background: none;
}
.secondary-menu ul {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.secondary-menu ul .num-bx {
  margin-right: 30px;
}
.secondary-menu ul .num-bx a {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.secondary-menu ul .num-bx a i {
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-color: rgba(239, 187, 32, 0.1);
  font-size: 18px;
  border-radius: 4px;
  color: #2b9159;
  margin-right: 15px;
}
@media (max-width: 1024px) {
  .secondary-menu .search-btn {
    margin-right: 25px;
  }
  .secondary-menu ul .num-bx {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .secondary-menu ul .num-bx {
    display: none;
  }
}
@media (max-width: 480px) {
  .secondary-menu {
    height: 85px;
  }
  .secondary-menu .btn-area {
    display: none;
  }
  .secondary-menu .search-btn {
    margin-right: 0;
  }
}
@media (max-width: 320px) {
  .secondary-menu {
    height: 75px;
  }
}

.navbar-toggler {
  font-size: 30px;
  line-height: 24px;
  margin: 35px 0 32px 20px;
  padding: 0;
  float: right;
  outline: none;

}
@media (max-width: 320px) {
  .navbar-toggler {
    margin: 28px 0 28px 20px;
  }
}

.menuicon {
  width: 20px;
  margin-right: 20px;
  height: 18px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}
.menuicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  background: #000;
}
.menuicon span:nth-child(1) {
  top: 0px;
}
.menuicon span:nth-child(2) {
  top: 7px;
}
.menuicon span:nth-child(3) {
  top: 14px;
}
.menuicon.open span:nth-child(1) {
  top: 7px;
  transform: rotate(135deg);
}
.menuicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.menuicon.open span:nth-child(3) {
  top: 7px;
  transform: rotate(-135deg);
}
.header-tb .menuicon span {
  background: #fff;
}

.menu-links {
  padding: 0;
}
.menu-links.show {
  @media (max-width: 768px) {
    left: -1px;
    transition: all 0.5s ease;
    margin: 0;
    width: 280px;
  }
}
.menu-links .menu-logo {
  display: none;
}
@media (max-width: 768px) {
  .menu-links .menu-logo {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}
.menu-links .nav {
  float: right;
  padding: 0;
  margin-right: 50px;
}
.menu-links .nav > li {
  margin: 0;
  font-weight: 400;
  position: relative;
}
.menu-links .nav > li.has-mega-menu {
  position: inherit;
}
@media (max-width: 768px) {
  .menu-links .nav > li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}
.menu-links .nav > li.active > a {
  color: #565acf;
}
.menu-links .nav > li > a {
  color: #1f2278;
  font-size: 16px;
  padding: 45px 20px;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  line-height: 1;
}
.menu-links .nav > li > a i {
  font-size: 10px;
  margin-left: 5px;
  margin-top: -3px;
  vertical-align: middle;
  color: #bbbbcf;
  transition: all 0.5s;
}
@media (max-width: 1024px) {
  .menu-links .nav > li > a {
    padding: 45px 15px;
  }
}
@media (max-width: 768px) {
  .menu-links .nav > li > a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    color: #fff;
  }
  .menu-links .nav > li > a i {
    right: 0;
    text-align: center;
    z-index: 3;
    float: right;
    margin: 10px 5px 0 0;
    font-size: 12px;
  }
}
.menu-links .nav > li .mega-menu,
.menu-links .nav > li .sub-menu {
  background-color: #002366;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 5px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu,
  .menu-links .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0;
  }
}
.menu-links .nav > li .mega-menu li,
.menu-links .nav > li .sub-menu li {
  position: relative;
}
.menu-links .nav > li .mega-menu li a,
.menu-links .nav > li .sub-menu li a {
  color: #ffffff;
  display: block;
  font-size: 16px;
  padding: 10px 25px;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  font-weight: 500;
}
.menu-links .nav > li .mega-menu li a span,
.menu-links .nav > li .sub-menu li a span {
  position: relative;
}
.menu-links .nav > li .mega-menu li a span:after,
.menu-links .nav > li .sub-menu li a span:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 0;
  background: #2b9159;
  bottom: 0;
  right: 0;
  transition: all 0.5s ease;
  transform-origin: right;
}
.menu-links .nav > li .mega-menu li a:hover,
.menu-links .nav > li .sub-menu li a:hover {
  color: #2b9159;
}
.menu-links .nav > li .mega-menu li a:hover span:after,
.menu-links .nav > li .sub-menu li a:hover span:after {
  width: 100%;
  transform-origin: left;
  left: 0;
  right: auto;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu li a,
  .menu-links .nav > li .sub-menu li a {
    padding: 10px 0px 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .menu-links .nav > li .mega-menu li a span:after,
  .menu-links .nav > li .sub-menu li a span:after {
    content: none;
  }
}
.menu-links .nav > li:hover > a {
  color: #2b9159;
}
.menu-links .nav > li .sub-menu li:hover > a {
  color: #2b9159;
}
.menu-links .nav > li .sub-menu li .fa {
  color: inherit;
  display: block;
  float: right;
  font-size: 9px;
  opacity: 1;
  margin-top: 4px;
}
.menu-links .nav > li .mega-menu {
  display: flex;
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 0;
}
.menu-links .nav > li .mega-menu > li {
  display: block;
  padding: 30px 0 25px;
  position: relative;
  width: 25%;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu > li {
    width: 100%;
    padding: 0;
  }
}
.menu-links .nav > li .mega-menu > li > a {
  color: #000;
  display: block;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.menu-links .nav > li .mega-menu > li > a i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu > li > a {
    display: none;
  }
}
.menu-links .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu > li:after {
    content: none;
  }
}
.menu-links .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.menu-links .nav > li .mega-menu > li:hover > a {
  background-color: transparent;
}
.menu-links .nav > li .mega-menu > li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media (max-width: 768px) {
  .menu-links .nav > li .mega-menu {
    display: none;
    padding: 10px 0;
  }
}
.menu-links .nav > li:hover > .mega-menu,
.menu-links .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  transition: all 0.5s ease;
}
.menu-links .nav > li:hover > a i {
  transform: rotate(45deg);
  color: #2b9159;
}
@media (max-width: 768px) {
  .menu-links .nav > li:hover > a i {
    transform: unset;
  }
}
.menu-links .nav > li.open > .mega-menu,
.menu-links .nav > li.open > .sub-menu {
  display: block;
  opacity: 1;
}
.menu-links .nav > li.open > a i {
  @media (max-width: 768px) {
    content: "\f068";
  }
}
@media (max-width: 1024px) {
  .menu-links .nav {
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .menu-links .nav {
    float: none;
    width: 70%;
    min-width: 300px;
  }
}
.menu-links .social-media {
  text-align: center;
  margin-top: 50px;
  margin: 0 -3px;
  display: none;
}
.menu-links .social-media li {
  display: inline-block;
  padding: 0 3px;
}
.menu-links .social-media li a {
  height: 40px;
  width: 40px;
  padding: 0;
  line-height: 38px;
}
.menu-links .menu-close {
  height: 40px;
  color: #fff!important;
  line-height: 44px;
  text-align: center;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  z-index: 9999;
}
@media (max-width: 768px) {
  .menu-links {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh;
    transition: all 0.5s ease;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .menu-links .social-media,
  .menu-links .menu-close {
    display: block;
  }
}

.top-bar {
  background-color: #191f23;
  color: #fff;
  padding: 8px 0;
}
@media (max-width: 320px) {
  .top-bar {
    display: none;
  }
}
.top-bar .topbar-left {
  float: left;
}
.top-bar .topbar-left li {
  padding-right: 15px;
}
.top-bar .topbar-right {
  float: right;
}
.top-bar .topbar-right li {
  padding-left: 15px;
}
.top-bar .topbar-center,
.top-bar .topbar-left,
.top-bar .topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}
.top-bar .topbar-center ul,
.top-bar .topbar-left ul,
.top-bar .topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-bar .topbar-center ul li,
.top-bar .topbar-left ul li,
.top-bar .topbar-right ul li {
  display: inline-block;
  font-size: 15px;
}
@media (max-width: 1024px) {
  .top-bar .topbar-center ul li,
  .top-bar .topbar-left ul li,
  .top-bar .topbar-right ul li {
    font-size: 13px;
  }
}
.top-bar .topbar-center ul li a,
.top-bar .topbar-left ul li a,
.top-bar .topbar-right ul li a {
  color: inherit;
}
.top-bar .topbar-center ul li i,
.top-bar .topbar-left ul li i,
.top-bar .topbar-right ul li i {
  color: #2b9159;
  transform: scale(1.3);
  margin-right: 5px;
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-transparent.style-1 .menu-links .nav > li > a {
  color: #1f2278;
}
.header-transparent.style-1 .menu-links .nav > li > a:hover {
  color: #565acf;
}
.header-transparent.style-1 .menu-links .nav > li > a:hover i {
  transform: rotate(45deg);
  color: #565acf;
}
.header-transparent.style-2 .menu-links .nav > li > a {
  color: #fff;
}
.header-transparent.style-2 .menu-links .nav > li > a:hover {
  color: #fff;
}
.header-transparent .top-bar,
/* .header-transparent .menu-bar {
  background-color: #002366;
  max-height: 100px;
} */
.header-transparent .top-bar {
  color: #777;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.menu-links .nav > li > a {
  padding: 35px 20px; 
}
.menu-logo {
  display: flex;
  align-items: center; 
  justify-content: center; 
  height: 50px; 
}

.menu-logo img {
  height: auto; 
  width: 72%; 
  margin-left: 30px;
}

.secondary-menu {
  display: flex;
  align-items: center; 
  height: 80px; 
}

.menuicon span {
  background: #fff; 
}
.menuicon:hover {
  background: none;
}

.user-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  padding: 10px;
  border-radius: 50%;
}

.user-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Estilos para el popup */
.popup-menu {
  position: absolute;
  top: 90px; 
  right: 130px; 
  background-color: white;
  border-radius: 12px;
  min-width: 270px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.popup-menu.show {
  opacity: 1;
  transform: translateY(0);
}
.popup-button {
  background-color: #2b9159;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px 0;
  width: 95%;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.popup-button svg {
  margin-right: 8px;
}

.popup-button:first-child {
  background-color: #2b9159;
}
.popup-button:first-child:hover {
  background-color: #1c7343
}
.popup-button:last-child {
  background-color: transparent;
  border: 2px solid #000000;
  color: #000000;
}

.popup-button:last-child:hover {
  background-color: #f0f0f0;
} 
.user-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  padding: 0; 
  border-radius: 50%; 
  width: 50px; 
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

/* Cambio de fondo cuando el popup está activo */
.user-icon-button.active {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 320px) {
  .header-transparent .top-bar {
    display: none;
  }
}

.sticky-header.active .menu-logo {
  transition: all 0.5s ease;
}
.sticky-header.active .menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}
.sticky-header.active .logo-change .logo1 {
  display: none;
}
.sticky-header.active .logo-change .logo2 {
  display: block;
}
.header-transparent .sticky-header.active .menu-bar {
  position: fixed;
  background-color: #fff;
}

@media (max-width: 768px) {
  .rs-nav .menu-links {
    position: fixed;
    width: 60px;
    left: -105%;
    height: 100vh;
    transition: all 0.5s ease;
    top: 0;
    background-color: #002366;
    margin: 0;
    z-index: 99;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: unset !important;
  }
  .rs-nav .menu-links.nav-dark {
    background-color: #002366;
  }
  .rs-nav .menu-links.show {
    left: 0;
    transition: all 0.5s ease;
    margin: 0;
    width: 100%;
    padding: 15px 15px 5px 15px;
  }
  .rs-nav .sticky-header.active .menu-links .nav {
    height: auto;
  }
  .rs-nav .social-media {
    margin-top: auto;
    padding: 30px 0;
  }
}
@media (max-width: 768px) {
  .navbar-nav {
    height: auto;
  }
}
.menu-links .nav > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.menu-links .nav > li a {
  font-size: 16px;
  color: #fff;
}
.menu-links .nav > li:hover,
.menu-links .nav > li.active {
  border-color: rgba(255, 255, 255, 0.1);
}
.menu-links .nav > li:hover > a,
.menu-links .nav > li.active > a {
  color: #fff;
}
.menu-links .nav > li .mega-menu li a,
.menu-links .nav > li .sub-menu li a {
  color: rgba(255, 255, 255, 0.7);
}
.menu-links .nav > li .mega-menu li:hover > a,
.menu-links .nav > li .sub-menu li:hover > a {
  background-color: transparent;
}
.header-transparent .sticky-header.active .menu-links .nav > li > a {
  color: #fff;
}
.custom-buttonone {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: #2b9159;
  color: white;
  cursor: pointer;
  font-size: 18px; 
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.custom-buttonone:hover {
  background-color: #000f1f;
  transform: translateY(-2px);
  font-weight: 900;
  color: white;
}

.custom-buttonone svg {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  fill: white;
}
.menu-links .nav, .secondary-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-buttonone {
  transition: background-color 0.2s, transform 0.2s; 
  margin-left: 15px; 
  margin-right: 15px; 
}
@media (max-width: 768px) {
  .custom-buttonone {
      width: auto; 
      margin: 10px 0; 
  }

  .popup-menu {
    position: absolute;
    top: 90px; 
    right: 0px; 
    background-color: white;
    border-radius: 12px;
    min-width: 270px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}
